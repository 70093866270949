import clsx from 'clsx'
import { LOCAL_STORAGE_KEYS } from 'common/constants'
import React, { FC, Dispatch, SetStateAction } from 'react'
import { useAuth } from '../../../../hooks/auth'
import { KTSVG } from '../../../helpers'

const tabs: ReadonlyArray<{ link: string; icon: string; tooltip: string }> = [
  {
    link: 'projects',
    icon: '/media/icons/duotune/general/gen025.svg',
    tooltip: 'Projects'
  },
  {
    link: 'menu',
    icon: '/media/icons/duotune/finance/fin006.svg',
    tooltip: 'Menu'
  },
  {
    link: 'subscription',
    icon: '/media/icons/duotune/general/gen032.svg',
    tooltip: 'Subscription'
  },
  {
    link: 'tasks',
    icon: '/media/icons/duotune/general/gen048.svg',
    tooltip: 'Tasks'
  },
  {
    link: 'notifications',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'Notifications'
  },
  {
    link: 'authors',
    icon: '/media/icons/duotune/files/fil005.svg',
    tooltip: 'Authors'
  }
]
interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  type: string
  icon: string
}
type Props = {
  link: Menu
  setLink: Dispatch<SetStateAction<Menu>>
}

const AsideTabs: FC<Props> = ({ link, setLink }) => {
  const { menus, user } = useAuth()
  return (
    <div
      className="hover-scroll-y mb-10"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-wrappers="#kt_aside_nav"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-offset="0px"
    >
      {/* begin::Nav */}
      <ul className="nav flex-column" id="kt_aside_nav_tabs">
        {/* begin::Nav item */}
        {menus
          .filter(menu => {
            if (user.role_id === 2) {
              if (menu.id === 9) {
                return true
              }
              if (menu.id === 15) {
                return true
              }
              return false
            }
            return true
          })
          .map(t => {
            return (
              <li
                key={t.id}
                className="mb-2 d-flex flex-column align-items-center"
              >
                {/* begin::Nav link */}
                <a
                  className={clsx(
                    'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
                    { active: true }
                  )}
                  href={t.url || undefined}
                  onClick={() => {
                    if (t.url) {
                      localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_MENU)
                      return
                    }
                    setLink(t)
                  }}
                >
                  {/* <KTSVG path={t.icon} className="svg-icon-2x" /> */}
                  <span className={t.icon} />
                </a>
                <span className="mt-2 text-gray-800 text-align-center">
                  {t.name}
                </span>
                {/* end::Nav link */}
              </li>
            )
          })}

        {/* end::Nav link */}
      </ul>
      {/* end::Tabs */}
    </div>
  )
}

// {user.role_id === 2 && (
//   <li
//     key={'t.id'}
//     className="mb-2 d-flex flex-column align-items-center"
//   >
//     {/* begin::Nav link */}
//     <a
//       className={clsx(
//         'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
//         { active: true }
//       )}
//       href="/condition-partners.pdf"
//       target="_blank"
//     >
//       {/* <KTSVG path={t.icon} className="svg-icon-2x" /> */}
//       <span className="fa fa-dollar" />
//     </a>
//     <span
//       className="mt-2 text-gray-800 text-align-center"
//       style={{ textAlign: 'center' }}
//     >
//       Condições Comerciais
//     </span>
//     {/* end::Nav link */}
//   </li>
// )}

export { AsideTabs }
