import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { Date as DatePicker } from '../../../../../components/Form/date'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { FormContent } from './styles'
import { WARNING_TYPE_OPTIONS } from 'common/constants/warning'
import { getFileName } from 'utlis/getFileName'
import { StringParam, useQueryParam } from 'use-query-params'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type WarningData = {
  title: string
  description?: string
  file?: string
  file_url?: string
  type: warningsTypes
  valid_at: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: WarningData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

const ContainerFile = ({
  initialValues,
  id
}: {
  initialValues: WarningData
  id: number | string
}) => {
  const history = useHistory()
  const [remove] = useQueryParam('remove', StringParam)

  if (!initialValues?.file) {
    return null
  }

  if (!remove || remove === 'false') {
    return (
      <div className="d-flex" style={{ alignItems: 'center' }}>
        <a
          href={initialValues.file_url}
          target="_blank"
          className="d-flex"
          rel="noreferrer"
          style={{ alignItems: 'center' }}
        >
          <span className="fa fa-download me-2"></span>
          <p>{getFileName({ fileName: initialValues.file })}</p>
        </a>
        <button
          className="btn btn-danger btn-sm ms-5"
          type="button"
          onClick={() => {
            history.replace(`/warnings/update/${id}?remove=true`)
          }}
        >
          Remover
        </button>
      </div>
    )
  }
  return (
    <div className="d-flex" style={{ alignItems: 'center' }}>
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={() => {
          history.replace(`/warnings/update/${id}?remove=false`)
        }}
      >
        Desfazer
      </button>
    </div>
  )
}

export const FormWarning = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const [remove] = useQueryParam('remove', StringParam)
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<WarningData>()
  const [validAtValue, setValidAtValue] = useState<Date>()
  const [id, setId] = useState<number>()

  useEffect(() => {
    if (initialValues) {
      setId(initialValues.idUpdate)
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: WarningData) => {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('file', data.file[0])
    formData.append('type', data.type)
    formData.append('valid_at', data.valid_at)
    if (remove === 'true') {
      await api.delete(`/warnings/deleteFile/${id}`)
    }

    try {
      if (typeForm === 'create') {
        try {
          activeLoading()
          await api.post(apiCreate(), formData)
          disableLoading()
          updateDataTable()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), formData)
          updateDataTable()
          disableLoading()

          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <FormContent modal={!!isOpenInModal} className="form">
          <div className="row mb-default">
            <Input
              name="title"
              className="col-6"
              label="Titulo"
              rules={{ required: true }}
            />

            <div className="col-md-3">
              <DatePicker
                name="valid_at"
                label="Valido até"
                selected={validAtValue}
                minDate={new Date()}
                onChange={e => setValidAtValue(e)}
                controlled
              />
            </div>

            <div className="col-md-3">
              <Input
                name="file"
                className="col mb-5"
                label="Arquivo"
                type="file"
                disabled={
                  initialValues?.file ? !remove || remove === 'false' : false
                }
              />
              <ContainerFile id={id} initialValues={initialValues} />
            </div>

            <Select
              className="col-3"
              name="type"
              label="Tipo de arquivo"
              options={WARNING_TYPE_OPTIONS}
              rules={{ required: false }}
              blank
            />

            <Textarea
              name="description"
              className="col-12"
              label="Descrição"
              rules={{ required: false, maxLength: 1330 }}
              style={{ minHeight: 100 }}
              maxLength={1330}
            />
          </div>
        </FormContent>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </>
    </Form>
  )
}
