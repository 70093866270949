export const headers = [
  { name: 'Cód.', field: 'id', sortable: true },
  { name: 'Nome/Razão Social', field: 'name', sortable: true },
  {
    name: 'CNPJ/CPF',
    field: 'document',
    sortable: true
  },
  { name: 'Celular', field: 'cell_phone', sortable: true },
  { name: 'E-mail', field: 'email', sortable: true },
  { name: 'Criado por', field: 'createdUser.name', sortable: true },
  { name: 'Ativo', field: 'active', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
export const headersDocuments = [
  { name: 'Arquivo', field: 'file', sortable: true, custom: true },
  { name: 'Observações', field: 'observations', sortable: true },
  { name: 'Cadastrador por', field: 'createdUser.name', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]
