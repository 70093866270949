import React, { useEffect, useState } from 'react'
import api from '../../../../../../services/api'
import Form, { Input, Select } from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type UserData = {
  name?: string
  role_id?: string
  email?: string
  username?: string
  password?: string
  active?: boolean
  remote_access?: boolean
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: UserData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

type Role = {
  id: number
  name: string
}

export const FormUser = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<UserData>()

  const [roles, setRoles] = useState<{ value: number; name: string }[]>([
    { value: 0, name: '' }
  ])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        name: initialValues.name,
        email: initialValues.email,
        username: initialValues.username,
        role_id: initialValues.role_id,
        active: initialValues.active,
        remote_access: initialValues.remote_access
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  async function getRoles() {
    const roles = (await api.get('/users/roles/')).data
    const rolesSelect = roles?.map((role: Role) => ({
      value: role.id,
      name: role.name
    }))
    setRoles(rolesSelect)
  }

  useEffect(() => {
    getRoles()
  }, [])

  const onSubmitForm = async (data: UserData) => {
    const id = initialValues?.idUpdate
    if (data.active) {
      data = {
        ...data
        // active: JSON.parse(String(data.active))
      }
    }
    if (data.remote_access) {
      data = {
        ...data
        // remote_access: JSON.parse(String(data.remote_access))
      }
    }

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate = {
            ...data,
            active: 1,
            parent_id: idParent
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              active: 'true',
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="row mb-default">
          <Input
            name="name"
            className="col-4"
            label="Nome"
            rules={{ required: typeForm === 'create' }}
          />
          <Input
            name="email"
            className="col-4"
            label="Email"
            rules={{ required: typeForm === 'create' }}
          />
          <Select
            className="col-2"
            name="role_id"
            label="Grupo"
            options={roles}
            blank
            defaultValue={''}
            rules={{ required: typeForm === 'create' }}
          />
          <Select
            className="col-2"
            name="remote_access"
            label="Acesso Remoto"
            options={[
              {
                name: 'Sim',
                value: 1
              },
              {
                name: 'Não',
                value: 0
              }
            ]}
          />
        </div>
        <div className="row mb-default">
          <Input
            name="username"
            className="col-4"
            label="Usuário"
            rules={{ required: typeForm === 'create' }}
          />
          <Input
            type="password"
            name="password"
            className="col-4"
            label="Senha"
            rules={{ required: typeForm === 'create' }}
          />
          {typeForm === 'update' && (
            <Select
              className="col-3"
              name="active"
              label="Ativo"
              options={[
                { value: 1, name: 'Sim' },
                { value: 0, name: 'Não' }
              ]}
            />
          )}
        </div>
        <div className="card-footer d-flex justify-content-end px-0 mt-5">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </>
    </Form>
  )
}
