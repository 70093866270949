export const headers = [
  { name: 'Parceiro', field: 'partner.name', sortable: true },
  {
    name: 'Comprador',
    field: 'customer_name',
    sortable: true,
    searchable: true
  },
  { name: 'Vendedor', field: 'seller_name', sortable: true, searchable: true },
  { name: 'Valor', field: 'immobile_value', sortable: true },
  { name: 'Cidade', field: 'immobile_city', sortable: true },
  {
    name: 'Status',
    field: 'statusProposal.name',
    sortable: true
  },
  { name: 'Próximo Atendimento', field: 'next_service', sortable: true },
  { name: 'Cadastrado Em', field: 'created_at', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
