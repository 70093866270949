import React, { useEffect, useState, useCallback, useRef } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { Alert } from 'components/Alert'
import moment from 'moment'
type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ProposalData = {
  id?: number
  financed_amount?: number
  status?: string
  proposal?: {
    partner_id: number
    customer_name: string
    customer_cpf: string
    immobile_value: string
  }
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProposalData
  typeForm: 'create' | 'update'
}
export const FormSimulation = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const submitButtonRef = useRef(null)
  const [defaultValues, setDefaultValues] = useState<ProposalData>()
  const [openAlertCreateHiring, setOpenAlertCreateHiring] =
    useState<boolean>(false)
  const [choiceCreateHiring, setChoiceCreateHiring] =
    useState<boolean>(undefined)
  const [banks, setBanks] = useState<any[]>([])
  const loadBanks = useCallback(async () => {
    activeLoading()
    try {
      const banksResponse = await api.get('/commercial/banks')
      setBanks(
        banksResponse.data.map(
          (bank: { id: number; name: string }) =>
            bank && { name: bank.name, value: bank.id }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    loadBanks()
  }, [loadBanks])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      // setPartnersSearch([
      //   {
      //     id: initialValues.partner_id,
      //     name: initialValues.partner?.name
      //   }
      // ])
    }
  }, [initialValues])

  const onSubmitForm = async (data: any) => {
    const id = initialValues?.id
    const formData = {
      ...data,
      proposal_id: Number(isOpenInModal?.idParent)
    }
    delete formData.data
    console.log(initialValues)
    console.log(formData)
    if (
      initialValues?.status !== 'Aprovado' &&
      formData.status === 'Aprovado' &&
      choiceCreateHiring === undefined
    ) {
      setOpenAlertCreateHiring(true)
      return
    }

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          try {
            await api.post('/commercial/proposalSimulations/create', formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          try {
            activeLoading()
            await api.put(
              `/commercial/proposalSimulations/update/${id}`,
              formData
            )
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    } finally {
      disableLoading()
    }

    if (choiceCreateHiring) {
      activeLoading()
      try {
        await api.post('/commercial/hirings/create', {
          partner_id: initialValues.proposal.partner_id,
          proposal_id: formData.proposal_id,
          bank_id: formData.bank_id,
          client_name: initialValues?.proposal?.customer_name,
          client_cpf: initialValues?.proposal?.customer_cpf,
          property_value: initialValues?.proposal?.immobile_value || '0,00',
          financed_value: formData.financed_amount,
          installment_value: formData.installment_value,
          term: formData.term,
          date: moment().format('YYYY-MM-DD 12:00:00'),
          interest_amount: formData.interest_amount
        })
        addToast({
          type: 'success',
          title: 'Contratação criada',
          description: 'Contratação criada com sucesso.'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro da contratação, por favor, tente novamente.'
        })
      } finally {
        disableLoading()
      }
    }
  }
  const handlerOnClickButtonCancelRemoveParent = () => {
    setChoiceCreateHiring(false)
    setOpenAlertCreateHiring(false)
  }

  const handlerOnClickButtonConfirmRemoveParentSimulation = useCallback(() => {
    setTimeout(() => {
      setChoiceCreateHiring(true)
      setOpenAlertCreateHiring(false)
      submitButtonRef?.current?.click()
    }, 100)
  }, [])

  return (
    <>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <>
          <div className="row mb-default">
            <Select
              className="col-6"
              label="Banco"
              name="bank_id"
              rules={{ required: true }}
              options={banks}
              defaultValue=""
              blank
            />
            <Input
              className="col-3"
              name="financed_amount"
              label="Valor Financiado"
              rules={{ required: false }}
              disabled
              price
            />
            <Input
              className="col-3"
              name="interest_amount"
              label="Percentual de Juros"
              rules={{ required: false }}
              price
            />
            <Input
              className="col-3"
              name="installment_value"
              label="Valor Parcela"
              rules={{ required: false }}
              price
            />
            <Input
              className="col-3"
              name="term"
              label="Prazo"
              rules={{ required: false }}
              mask={{
                mask: '999'
              }}
              controlled
            />
            {typeForm === 'update' && (
              <Select
                className="col-3"
                label="Status"
                name="status"
                rules={{ required: true }}
                options={[
                  {
                    name: 'Aguardando Aprovação',
                    value: 'Aguardando Aprovação'
                  },
                  {
                    name: 'Aprovado',
                    value: 'Aprovado'
                  },
                  {
                    name: 'Cancelado',
                    value: 'Cancelado'
                  },
                  {
                    name: 'Reprovado',
                    value: 'Reprovado'
                  }
                ]}
                defaultValue=""
                blank
              />
            )}
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              ref={submitButtonRef}
            >
              Salvar
            </button>
          </div>
        </>
      </Form>
      {openAlertCreateHiring && (
        <Alert
          message={'Deseja cria uma contratação?'}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParentSimulation()
          }
          isActive={openAlertCreateHiring}
          textCancel="Não"
          textConfim="Sim"
        />
      )}
    </>
  )
}
