import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, nameEntity } from '../domain/info'
import { apiDelete, apiList, apiUpdate } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import Modal from '../../../../components/Modal'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { cnpjMask, cpfMask } from '../../../../utlis/mask'
import convertStringOrNumberToStringBoolean from 'utlis/convertStringOrNumberToStringBoolean'
import { Title } from 'components/Title'
import { TYPE_PERSON } from 'common/constants'
import { headersDocuments } from '../domain/headers'
import { FormFile } from '../components/FormFile'
import { FormChangeStatus } from '../components/FormChangeStatus'

interface PartnerData {
  id: number
  name: string
  type: string
  document: string
  zip_code: string
  state: string
  district: string
  street: string
  city: string
  complement?: string
  observations?: string
  active: number
  create_user: number
  name_ownership: string
  cpf_cnpj_ownership: string
  type_person: string
  type_account: string
  bank_code: string
  agency: string
  account: string
  account_digit: string
  email: string
  secondary_email: string
  phone: string
  cell_phone: string
  own_team: number
  indicator: number
  receive_help_cost: number
  created_by?: number
  different_ownership?: number
  number?: string
  created_at?: string
  updated_at?: string
  issue_invoice?: number
  approved?: number
  approved_at?: string
  pix_key?: string
  type_pix_key?: string
  approved_reason?: string
  agreementGroup: {
    name: string
  }
  user: {
    id: number
    name: string
  }
  createdUser?: { name: string }
  approvedUser?: { name: string }
  partnerFiles?: {
    file: string
    file_uuid: string
    signed_at: string
    contract_template_id: number
  }[]
}

const View = (): JSX.Element => {
  const { disableLoading, activeLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [partner, setPartner] = useState<PartnerData | null>(null)
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])
  const [modalChangeStatus, setModalChangeStatus] = useState(false)

  const [modalCreate, setModalCreate] = useState(false)
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any>({} as any)

  const [modalEdit, setModalEdit] = useState(false)
  const searchParametersPartnerFile = useRef([{ partner_id: id }])
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const loadPartner = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<PartnerData>(apiList(id))
      const { data } = response
      setPartner(data)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar a prospecção',
        description:
          'Houve um error ao carregar a prospecção, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])
  useEffect(() => {
    loadPartner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(`commercial/partnerFiles/delete/${id}`)
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Arquivo removido com sucesso.'
      })
      loadPartner()
      updateDataTable()
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Arquivo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnClose = () => {
    setModalCreate(false)
    setModalEdit(false)
    setModalChangeStatus(false)
    loadPartner()
  }
  const handleCnpjCpfMask = (value: string) => {
    if (value.length === 14) {
      return cnpjMask(value)
    }
    return cpfMask(value)
  }

  const handleClickOnOpenModalCreate = () => {
    setModalCreate(true)
  }

  const handleNameOfFile = (item: { file: string }) => {
    let filename = item?.file
    const indexTrace = filename.indexOf('-') + 1
    filename = filename.slice(indexTrace, filename.length)
    return filename
  }

  const handleDownload = useCallback(
    (item: { file: string; file_url?: string; file_uuid?: string }) => {
      const filename = handleNameOfFile(item)
      if (item.file_uuid) {
        const a = document.createElement('a')
        a.download = item.file
        a.href = item.file_url
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        return
      }
      fetch(item?.file_url).then(response => {
        response.blob().then((blobObject: any) => {
          const url = window.URL.createObjectURL(blobObject)
          const a = document.createElement('a')
          a.download = filename
          a.href = url
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
      })
    },
    []
  )
  const handleUpdateStatusPartner = useCallback(async () => {
    activeLoading()
    try {
      await api.put(apiUpdate(id), {
        ...partner,
        approved_reason: null
      })
      await loadPartner()
      addToast({
        type: 'success',
        title: 'Parceiro atualizado com sucesso.'
      })
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o parceiro.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading, id, loadPartner, partner])

  const handlerOnClickButtonEditInCurrentRow = (currentValue: any) => {
    setCurrentItemUpdate(currentValue)
    setModalEdit(true)
  }

  const handlerOnClickButtonRemoveInCurrentRow = ({ id, name }: any) => {
    setIsActiveAlert({ id, name, isActive: true })
  }
  const handleSendEmailWelcome = async () => {
    activeLoading()
    try {
      await api.post(`/commercial/partners/sendEmail/${id}`)
      addToast({
        type: 'success',
        title: 'Email enviado com sucesso.'
      })
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível enviar email ao parceiro.'
      })
      disableLoading()
    }
  }

  const handleContract = useCallback(async () => {
    activeLoading()
    try {
      await api.post('/commercial/d4sign/document', {
        template_id: 1,
        partner_id: partner?.id
      })
      disableLoading()
      addToast({
        title: 'Documento enviado',
        description: 'Documento enviado com sucesso',
        type: 'success'
      })
    } catch (error: any) {
      disableLoading()
      addToast({
        title: 'Erro ao enviar documento',
        description:
          error?.response?.data?.message ||
          'Ocorreu um erro ao enviar o documento para assinatura, tente novamente mais tarde',
        type: 'error'
      })
    }
  }, [activeLoading, addToast, disableLoading, partner?.id])

  const handleCancellation = useCallback(async () => {
    const lastIndex = partner?.partnerFiles?.length - 1
    activeLoading()
    try {
      await api.post('/commercial/d4sign/document', {
        template_id: 2,
        partner_id: partner?.id,
        signed_file_uuid: partner?.partnerFiles?.[lastIndex]?.file_uuid
      })
      addToast({
        title: 'Documento enviado',
        description: 'Documento enviado com sucesso',
        type: 'success'
      })
      disableLoading()
    } catch (error: any) {
      disableLoading()
      addToast({
        title: 'Erro ao enviar documento',
        description:
          error?.response?.data?.message ||
          'Ocorreu um erro ao enviar o documento para assinatura, tente novamente mais tarde',
        type: 'error'
      })
    }
  }, [
    activeLoading,
    addToast,
    disableLoading,
    partner?.id,
    partner?.partnerFiles
  ])

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-default">
            <Title title="Dados Pessoais" className="mt-0" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód.
                </label>
                <p className=" fs-6 text-gray-800">{partner?.id}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{partner?.name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  Tipo Pessoa
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.type_person?.toUpperCase() === 'PF'
                    ? 'Pessoa Física'
                    : 'Pessoa Jurídica'}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  {partner?.type?.toUpperCase() ===
                  TYPE_PERSON.PF.ABBREVIATION.toUpperCase()
                    ? TYPE_PERSON.PF.DOCUMENT.toUpperCase()
                    : TYPE_PERSON.PJ.DOCUMENT.toUpperCase()}
                </label>
                <p className=" fs-6 text-gray-800">{partner?.document}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">{partner?.type}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Endereço" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  CEP
                </label>
                <p className=" fs-6 text-gray-800">{partner?.zip_code}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Logradouro
                </label>
                <p className=" fs-6 text-gray-800">{partner?.street}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Número
                </label>
                <p className=" fs-6 text-gray-800">{partner?.number}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Complemento
                </label>
                <p className=" fs-6 text-gray-800">{partner?.complement}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Bairro
                </label>
                <p className=" fs-6 text-gray-800">{partner?.district}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cidade
                </label>
                <p className=" fs-6 text-gray-800">{partner?.city}</p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado
                </label>
                <p className=" fs-6 text-gray-800">{partner?.state}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Contatos" />

            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail
                </label>
                <p className=" fs-6 text-gray-800">{partner?.email}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail Secundário
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.secondary_email}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Celular
                </label>
                <p className=" fs-6 text-gray-800">{partner?.cell_phone}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Telefone
                </label>
                <p className=" fs-6 text-gray-800">{partner?.phone}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Dados Bancários" />

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Titularidade diferente
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(
                    partner?.different_ownership
                  )}
                </p>
              </div>
            </div>
            {!!partner?.different_ownership && (
              <>
                <div className="col-2">
                  <div className="form-group">
                    <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                      Nome do Titular
                    </label>
                    <p className=" fs-6 text-gray-800">
                      {partner?.name_ownership}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                      CPF/CNPJ do Titular
                    </label>
                    <p className=" fs-6 text-gray-800">
                      {handleCnpjCpfMask(partner?.cpf_cnpj_ownership)}
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">{partner?.type_account}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód. Banco
                </label>
                <p className=" fs-6 text-gray-800">{partner?.bank_code}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Agência
                </label>
                <p className=" fs-6 text-gray-800">{partner?.agency}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Conta
                </label>
                <p className=" fs-6 text-gray-800">{partner?.account}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Conta DV
                </label>
                <p className=" fs-6 text-gray-800">{partner?.account_digit}</p>
              </div>
            </div>
          </div>

          <div className="row mb-default ">
            <Title
              hasSeparator={false}
              CustomTitle={() => {
                return <h5>Pix</h5>
              }}
            />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo de Chave
                </label>
                <p className=" fs-6 text-gray-800">{partner?.type_pix_key}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Chave
                </label>
                <p className=" fs-6 text-gray-800">{partner?.pix_key}</p>
              </div>
            </div>
          </div>
          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Sistema" />

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Ativo
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.active ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Emite NF
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(partner?.issue_invoice)}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Equipe Própria
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(partner?.own_team)}
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Indicador
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(partner?.indicator)}
                </p>
              </div>
            </div>
            {partner?.approved ? (
              <>
                <div className="col-2">
                  <div className="form-group">
                    <label
                      htmlFor="name"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Aprovado em
                    </label>
                    <p className=" fs-6 text-gray-800">
                      {partner?.approved_at}
                    </p>
                    <a
                      href="#"
                      onClick={handleSendEmailWelcome}
                      className="d-flex align-items-center mt-2"
                    >
                      <span className="fa fa-envelope  me-2"></span>
                      <p>Reenviar Email de Boas Vindas</p>
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label
                      htmlFor="name"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Aprovado por
                    </label>
                    <p className=" fs-6 text-gray-800">
                      {partner?.approvedUser?.name}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-2">
                <div className="form-group">
                  <label
                    htmlFor="name"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Status
                  </label>
                  {partner?.approved_reason?.length ? (
                    <>
                      <p className=" fs-6 text-gray-800">
                        {Number(partner?.approved) ? 'Aprovado' : 'Reprovado'}
                      </p>
                      <button
                        className="btn btn-primary"
                        onClick={handleUpdateStatusPartner}
                        disabled={!partner?.partnerFiles?.length}
                      >
                        Solicitar Aprovação
                      </button>
                    </>
                  ) : (
                    <>
                      <p className=" fs-6 text-gray-800">
                        Aguardando Aprovação
                      </p>
                      <button
                        className="btn btn-primary"
                        onClick={() => setModalChangeStatus(true)}
                        disabled={!partner?.partnerFiles?.length}
                      >
                        Alterar Status
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* <div className="separator my-5" /> */}

          {!!partner?.observations.length && (
            <div className="row mb-default">
              <Title title="Observações" />

              <div className="col-12">
                <div className="form-group">
                  <p className=" fs-6 text-gray-800">{partner?.observations}</p>
                </div>
              </div>
            </div>
          )}

          <div className="row mb-default">
            <Title title="Informações Adicionais" />

            <div className="col-2">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Criador por
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.createdUser?.name}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Existe Usuário
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.user ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cadastrado em
                </label>
                <p className=" fs-6 text-gray-800">{partner?.created_at}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Atualizado em
                </label>
                <p className=" fs-6 text-gray-800">{partner?.updated_at}</p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Assinatura Digital de Contratos" />
            <div className="col-1">
              <button
                type="button"
                onClick={handleContract}
                className="btn btn-primary"
              >
                Parceria
              </button>
            </div>
            <div className="col-2">
              <button
                type="button"
                onClick={handleCancellation}
                className="btn btn-primary"
                disabled={
                  !(
                    partner?.partnerFiles?.[partner?.partnerFiles?.length - 1]
                      ?.contract_template_id === 1 &&
                    partner?.partnerFiles?.[partner?.partnerFiles?.length - 1]
                      ?.signed_at
                  )
                }
              >
                Distrato Parceria
              </button>
            </div>
          </div>

          <div className="row mb-default">
            <div className="">
              <Tabs>
                <Tab title="Documentos" key={0}>
                  <div className="p-9">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 col">
                        <p>Listagem</p>
                      </div>
                      <div
                        className="d-flex align-items-center text-primary"
                        onClick={handleClickOnOpenModalCreate}
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="fa fa-plus " />
                        <span className="ms-2">Adicionar</span>
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        format={{ orderBy: '' }}
                        source="partnerFile"
                        entity="PartnerFile"
                        headers={headersDocuments}
                        searchParameters={searchParametersPartnerFile.current}
                        customHeaders={[
                          {
                            name: 'Arquivo',
                            field: 'file',
                            sortable: true,
                            element: item => {
                              return <p>{handleNameOfFile(item)}</p>
                            }
                          },
                          {
                            name: 'Ações',
                            field: 'actions',
                            sortable: false,
                            element: item => {
                              return (
                                <div className="d-flex justify-content-center">
                                  <span
                                    className={
                                      'fa fa-download text-primary me-2 '
                                    }
                                    style={{
                                      cursor: 'pointer',

                                      pointerEvents: item.file_uuid
                                        ? item.downloaded
                                          ? 'all'
                                          : 'none'
                                        : 'all'
                                    }}
                                    onClick={() => {
                                      handleDownload({
                                        file: item.file,
                                        file_url: item.file_url,
                                        file_uuid: item.file_uuid
                                      })
                                    }}
                                  ></span>
                                  {!item.file_uuid && (
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      className="fa fa-edit text-primary me-2 pointer"
                                      onClick={() => {
                                        handlerOnClickButtonEditInCurrentRow(
                                          item
                                        )
                                      }}
                                    ></span>
                                  )}
                                  {!item.file_uuid && (
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      className="fa fa-remove text-primary pointer"
                                      onClick={() => {
                                        handlerOnClickButtonRemoveInCurrentRow({
                                          id: item.id,
                                          name: handleNameOfFile(item)
                                        })
                                      }}
                                    ></span>
                                  )}
                                </div>
                              )
                            }
                          }
                        ]}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab key={1} title="Histórico">
                  <div className="p-9">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 col">
                        <p>Listagem</p>
                      </div>
                    </div>
                    <div className="">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${partner?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(partner?.id))
        }
        isActive={alertRemoveParent}
      />
      {modalChangeStatus && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalChangeStatus}
          pageTitle="Alterar Status"
          styles={{ padding: '30px 250px' }}
          Children={
            <FormChangeStatus
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
              initialValues={partner}
            />
          }
        />
      )}
      {modalCreate && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalCreate}
          pageTitle={'Adicionar Documento'}
          styles={{ padding: '30px 250px' }}
          Children={
            <FormFile
              typeForm="create"
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={'Editar'}
          styles={{ padding: '30px 250px' }}
          Children={
            <FormFile
              typeForm="update"
              initialValues={{
                ...currentItemUpdate
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
    </>
  )
}

export default View
