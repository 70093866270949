import React, { HTMLAttributes } from 'react'
import { Container } from './styles'

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  hasSeparator?: boolean
  CustomTitle?: () => JSX.Element
}
export const Title: React.FC<TitleProps> = (
  { title, className, CustomTitle, hasSeparator = true },
  ...rest
) => {
  return (
    <Container className={'mt-5 ' + className} {...rest}>
      {CustomTitle?.() || <h3>{title}</h3>}
      {hasSeparator && <div className="separator my-5" />}
    </Container>
  )
}
