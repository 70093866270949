import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { FormCreditLine } from '../components/Form'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { useToast } from '../../../../hooks/toast'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { apiList } from '../domain/api'
import { toolsUpdate } from '../domain/tools'

const CreditLineUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [creditLineItem, setCreditLineItem] = useState<{
    id?: number
    name?: string
    created_at?: string
    updated_at?: string
  }>()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setCreditLineItem(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o parceiro',
          description:
            'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormCreditLine
        typeForm="update"
        initialValues={{
          idUpdate: Number(creditLineItem?.id),
          ...creditLineItem
        }}
      />
    </Container>
  )
}

export default CreditLineUpdate
