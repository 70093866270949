import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/StatusHirings/List'
import Create from '../../pages/Commercial/StatusHirings/Create'
import View from '../../pages/Commercial/StatusHirings/View'
import Update from '../../pages/Commercial/StatusHirings/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const StatusHiringsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/statusHirings" exact component={List} />
    <PrivateRoutes
      path="/commercial/statusHirings/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/statusHirings/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/statusHirings/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
