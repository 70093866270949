import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { Title } from 'components/Title'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import DataTable from 'components/DataTable'
import { useAuth } from 'hooks/auth'
import { useUpdateDataTable } from 'hooks/dataTable'
import { FormFile } from '../components/FormFile'
import Modal from 'components/Modal'

const View = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { user } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any>({} as any)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalCreate, setModalCreate] = useState(false)
  const [hiring, setHiring] = useState<HiringResponseApi | null>(null)
  const searchParametersHiringStatus = useRef([{ hiring_id: id }])
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { activeLoading, disableLoading } = useLoading()

  const loadHiring = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<HiringResponseApi>(apiList(id))
      const { data } = response
      setHiring(data)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar a prospecção',
        description:
          'Houve um error ao carregar a prospecção, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  useEffect(() => {
    loadHiring()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnOpenModalCreate = () => {
    console.log('AS')
    setModalCreate(true)
  }
  const handleNameOfFile = (item: { filename: string }) => {
    let filename = item?.filename
    const indexTrace = filename.indexOf('-') + 1
    filename = filename.slice(indexTrace, filename.length)
    return filename
  }
  const handleDownload = useCallback(
    (item: { filename: string; url: string }) => {
      const filename = handleNameOfFile(item)
      fetch(item?.url).then(response => {
        response.blob().then((blobObject: any) => {
          const url = window.URL.createObjectURL(blobObject)
          const a = document.createElement('a')
          a.download = filename
          a.href = url
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
      })
    },
    []
  )

  const handleDuplicatedRow = async (currentValue: any) => {
    activeLoading()
    try {
      const formData = new FormData()
      for (const file of currentValue.files) {
        const blob = await fetch(file.url).then(r => r.blob())
        const filename = handleNameOfFile(file)
        const newFile = new File([blob], filename)
        formData.append('file', newFile)
      }
      formData.append('hiring_id', currentValue.hiring_id)
      formData.append('status_id', currentValue.status_id)
      formData.append('observations', currentValue.observations)
      await api.post('/commercial/hiringsStatus/create', formData)
      updateDataTable()
    } catch (error) {}
    disableLoading()
  }
  const handlerOnClickButtonEditInCurrentRow = (currentValue: any) => {
    setCurrentItemUpdate(currentValue)
    setModalEdit(true)
  }

  const handleClickOnClose = async () => {
    setModalCreate(false)
    setModalEdit(false)
    await loadHiring()
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-default">
            <Title title="Detalhes" className="mt-0" />
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cód.
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nº da Proposta
                </label>
                <p className=" fs-6 text-gray-800">
                  {hiring?.number_hiring_bank}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Parceiro
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.partner?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Banco
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.bank?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nome do cliente
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.client_name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  CPF do cliente
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.client_cpf}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Valor do imóvel
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.property_value}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Valor financiado
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.financed_value}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Valor da parcela
                </label>
                <p className=" fs-6 text-gray-800">
                  {hiring?.installment_value}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Prazo
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.term}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Status
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.status}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Data
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.date}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Taxa de juros
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.interest_amount}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="updated"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Atualizado em
                </label>
                <p className=" fs-6 text-gray-800">{hiring?.updated_at}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="">
            <Tabs>
              <Tab key={0} title="Status">
                <div className="p-9">
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalCreate}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Adicionar</span>
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      format={{ orderBy: 'id' }}
                      orderBySort="DESC"
                      source="hiringStatus"
                      entity="HiringStatus"
                      headers={[
                        {
                          name: 'Data',
                          field: 'created_at',
                          sortable: true
                        },
                        {
                          name: 'Status',
                          field: 'statusHiring.name',
                          sortable: true
                        },
                        {
                          name: 'Observações',
                          field: 'observations',
                          sortable: true
                        },
                        {
                          name: 'Anexos',
                          field: 'files',
                          sortable: false,
                          custom: true
                        },
                        {
                          name: 'Usuário',
                          field: 'createdUser.name',
                          sortable: false
                        },
                        {
                          name: 'Ações',
                          field: 'actions',
                          sortable: false
                        }
                      ]}
                      customHeaders={[
                        {
                          name: 'Anexos',
                          field: 'files',
                          sortable: false,
                          element: item => {
                            const files = item?.files

                            return (
                              <div>
                                {files?.map((file: any) => (
                                  <div
                                    key={file.filename}
                                    onClick={() => handleDownload(file)}
                                    className="d-flex align-items-center text-primary cursor-pointer"
                                  >
                                    <span className="fa fa-download me-2"></span>
                                    <p>{file.filename}</p>
                                  </div>
                                ))}
                              </div>
                            )
                          }
                        },
                        {
                          name: 'Ações',
                          field: 'actions',
                          sortable: false,
                          element: () => (
                            <div>
                              <p></p>
                            </div>
                          )
                        }
                      ]}
                      actions={[
                        {
                          name: 'Duplicar',
                          title: 'Duplicar',
                          // spanIcon: 'fa fa-refresh',
                          onClick: subMenu => {
                            handleDuplicatedRow(subMenu)
                          },
                          element: item => {
                            const verifyIfUserIsSameCreated =
                              Number(item.created_by) === Number(user.id)
                            const verifyIfItemIsMostRecent =
                              Number(item.id) === Number(item.recentItemId)
                            if (
                              verifyIfUserIsSameCreated &&
                              verifyIfItemIsMostRecent
                            ) {
                              return (
                                <span
                                  className="fa fa-refresh"
                                  onClick={item => handleDuplicatedRow(item)}
                                ></span>
                              )
                            }
                            return <div></div>
                          }
                        },
                        {
                          name: 'Editar',
                          title: 'Editar',
                          element: item => {
                            const verifyIfUserIsSameCreated =
                              Number(item.created_by) === Number(user.id)
                            const verifyIfItemIsMostRecent =
                              Number(item.id) === Number(item.recentItemId)
                            if (
                              verifyIfUserIsSameCreated &&
                              verifyIfItemIsMostRecent
                            ) {
                              return (
                                <span
                                  className="fa fa-edit"
                                  onClick={() =>
                                    handlerOnClickButtonEditInCurrentRow({
                                      ...item
                                    })
                                  }
                                ></span>
                              )
                            }
                            return <div></div>
                          }
                        }
                      ]}
                      searchParameters={searchParametersHiringStatus.current}
                    />
                  </div>
                </div>
              </Tab>
              <></>
            </Tabs>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${hiring?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(hiring?.id))
        }
        isActive={alertRemoveParent}
      />
      {modalCreate && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalCreate}
          pageTitle="Atualização de Status"
          styles={{ padding: '30px 250px' }}
          Children={
            <FormFile
              typeForm="create"
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
              initialValues={hiring}
            />
          }
        />
      )}
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={'Editar'}
          styles={{ padding: '30px 250px' }}
          Children={
            <FormFile
              typeForm="update"
              initialValues={{
                ...currentItemUpdate
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
    </>
  )
}

export default View
