export const headers = [
  {
    name: 'Nº da Proposta',
    field: 'number_hiring_bank',
    sortable: true,
    custom: false
  },
  { name: 'Parceiro', field: 'partner_id', sortable: true, custom: true },
  { name: 'Banco', field: 'bank_id', sortable: true, custom: true },
  { name: 'Cliente', field: 'client_name', sortable: true, custom: false },
  // { name: 'Valor do imóvel', field: 'property_value', sortable: true },
  { name: 'Valor financiado', field: 'financed_value', sortable: true },
  // { name: 'Taxa de juros', field: 'interest_amount', sortable: true },
  // { name: 'Prazo', field: 'term', sortable: true },
  { name: 'Status', field: 'status', sortable: true, custom: true },
  { name: 'Data', field: 'date', sortable: true },
  { name: 'Cadastrado Em', field: 'created_at', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
