import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/CreditLines/List'
import Create from '../../pages/Commercial/CreditLines/Create'
import View from '../../pages/Commercial/CreditLines/View'
import Update from '../../pages/Commercial/CreditLines/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const CreditLinesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/creditLines" exact component={List} />
    <PrivateRoutes
      path="/commercial/creditLines/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/creditLines/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/creditLines/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
