import React, { useEffect, useState, useCallback } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { SearchComponentProduct } from '../SearchComponentProduct'
import { Title } from 'components/Title'
import {
  dateInputFormat,
  INPUT_MASK,
  OPTIONS_CREDIT_LINE,
  OPTIONS_IMMOBILE_SITUATION,
  OPTIONS_IMMOBILE_TYPES,
  OPTIONS_INDICATION_TYPE,
  OPTIONS_MARITAL_STATUS,
  states
} from 'common/constants'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import { Loading } from 'components/Loading'
import { PROPOSAL_PRODUCT_TYPE_OPTIONS } from 'common/constants/proposal'
import { useAuth } from 'hooks/auth'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ProposalData = {
  id?: number
  seller_birth_date?: string
  seller_phone?: null
  seller_cell_phone?: string
  seller_email?: string
  seller_marital_status?: string
  seller_zip_code?: string
  seller_state?: string
  seller_district?: string
  seller_street?: string
  seller_number?: string
  seller_city?: string
  seller_complement?: string
  customer_birth_date?: string
  customer_phone?: null
  customer_cell_phone?: string
  customer_email?: string
  customer_fgts_value?: string
  customer_gross_value?: string
  customer_entry_value?: string
  customer_hiring_term?: string
  customer_bank_id?: 1
  customer_marital_status?: string
  customer_zip_code?: string
  customer_state?: string
  customer_district?: string
  customer_street?: string
  customer_number?: string
  customer_city?: string
  customer_complement?: string
  immobile_type?: string
  immobile_situation?: string
  immobile_registry?: string
  immobile_registration?: string
  immobile_value?: string
  immobile_zip_code?: string
  immobile_state?: string
  immobile_district?: string
  immobile_street?: string
  immobile_number?: string
  immobile_city?: string
  immobile_complement?: string
  iptu_cadastral_index?: string
  created_by?: number
  status_id?: number
  origin_id?: string
  responsible_name?: string
  responsible_email?: string
  responsible_cell_phone?: string
  next_service?: string
  customer_name?: string
  customer_cpf?: string
  seller_name?: string
  seller_cpf?: string
  created_at?: string
  updated_at?: string
  partner_id?: number
  peoples_involvers?: any
  partner?: {
    name: string
  }
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ProposalData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
interface FormAddress {
  prefix: string
}

const prefixSeller = 'seller_'
const prefixCustomer = 'customer_'
export const FormProposal = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { user } = useAuth()
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<ProposalData>()
  const [partners, setPartners] = useState<any[]>([])
  const [banks, setBanks] = useState<any[]>([])
  const [creditLines, setCreditLines] = useState<any[]>([])
  const [indicationTypes, setIndicationTypes] = useState<any[]>([])
  const [sellerBirthDate, setSellerBirthDate] = useState<Date>()
  const [customerBirthDate, setCustomerBirthDate] = useState<Date>()
  const [customerCpf, setCustomerCpf] = useState('')
  const [sellerCpf, setSellerCpf] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [origins, setOrigins] = useState([])
  const [lastId, setLastId] = useState(undefined)
  const maxDate = moment().subtract(18, 'y').toDate()
  const [peopleInvolvers, setPeopleInvolved] = useState<
    {
      id?: number
      name?: string
      email?: string
      indication_type?: string
      cell_phone?: string
    }[]
  >([])
  const [partnersSearch, setPartnersSearch] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [selectedPartner, setSelectedPartner] = useState([
    { id: 0, selected: false }
  ])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])
  const handleSetBirthDate: { [key: string]: (value: any) => void } = {
    customer: value => {
      if (value) {
        setCustomerBirthDate(moment(value, dateInputFormat).toDate())
      }
      return value
    },
    seller: value => {
      if (value) {
        setSellerBirthDate(moment(value, dateInputFormat).toDate())
      }
      return value
    }
  }
  const loadBanks = useCallback(async () => {
    activeLoading()
    try {
      const banksResponse = await api.get('/commercial/banks')
      setBanks(
        banksResponse.data.map(
          (bank: { id: number; name: string }) =>
            bank && { name: bank.name, value: bank.id }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  const loadCreditLines = useCallback(async () => {
    activeLoading()
    try {
      const creditLinesResponse = await api.get('/commercial/creditLines')
      setCreditLines(
        creditLinesResponse.data.map(
          (creditLine: { id: number; name: string }) =>
            creditLine && { name: creditLine.name, value: creditLine.id }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar linhas de crédito',
        description:
          'Houve um error ao carregar as linhas de crédito, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  const loadIndicationTypes = useCallback(async () => {
    activeLoading()
    try {
      const indicationTypesResponse = await api.get(
        '/commercial/indicationTypes'
      )
      setIndicationTypes(
        indicationTypesResponse.data.map(
          (indicationType: { id: number; name: string }) =>
            indicationType && {
              name: indicationType.name,
              value: indicationType.name
            }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar tipos de indicação',
        description:
          'Houve um error ao carregar as tipos de indicação, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  const loadOrigins = useCallback(async () => {
    activeLoading()
    try {
      const originsResponse = await api.get('/commercial/origins')
      setOrigins(
        originsResponse.data.map(
          (origin: { id: number; name: string }) =>
            origin && { name: origin.name, value: Number(origin.id) }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  const loadPartners = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/commercial/partners')
      setPartners(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    loadOrigins()
    if (user.role_id === 1) {
      loadPartners()
    }
    loadBanks()
    loadCreditLines()
    loadIndicationTypes()
  }, [
    loadBanks,
    loadCreditLines,
    loadIndicationTypes,
    loadOrigins,
    loadPartners,
    user.role_id
  ])
  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      setCustomerCpf(initialValues.customer_cpf)
      setSellerCpf(initialValues.seller_cpf)
      if (
        initialValues?.peoples_involvers &&
        initialValues?.peoples_involvers?.length
      ) {
        const filterPeoplesInvolved = initialValues.peoples_involvers.filter(
          (peopleInvolved: any) => peopleInvolved
        )
        setPeopleInvolved([...filterPeoplesInvolved])
        setLastId(
          Number(
            [...filterPeoplesInvolved][filterPeoplesInvolved.length - 1].id
          )
        )
      }
      setPartnersSearch([
        {
          id: initialValues.partner_id,
          name: initialValues.partner?.name
        }
      ])
    }
  }, [initialValues])

  const onSubmitForm = async (data: any) => {
    const id = initialValues?.idUpdate
    const formData = {
      ...data,
      partner_id: user.role_id === 1 ? Number(data.data[0].id) : user.uid,
      peoples_involvers: peopleInvolvers
    }
    delete formData.data
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          try {
            await api.post(apiCreate(), formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  const getSearched = async (value: string, type: string) => {
    setIsLoading(true)
    const cpf = type + '_cpf'
    try {
      const proposalResponse = await api.post('/commercial/proposals/view', {
        cpf: value,
        type
      })
      if (proposalResponse.data?.message) {
        if (typeForm === 'update') {
          setDefaultValues(oldDefaultValues => ({
            ...oldDefaultValues,
            [cpf]: value
          }))
        }
        setIsLoading(false)
        return
      }
      handleSetBirthDate[type]?.(proposalResponse.data[type + '_birth_date'])
      const name = type + '_name'

      const birth_date = type + '_birth_date'
      const phone = type + '_phone'
      const cell_phone = type + '_cell_phone'
      const email = type + '_email'
      const marital_status = type + '_marital_status'
      const zip_code = type + '_zip_code'
      const state = type + '_state'
      const district = type + '_district'
      const street = type + '_street'
      const number = type + '_number'
      const city = type + '_city'
      const complement = type + '_complement'
      const fgts_value = type + '_fgts_value'
      const gross_value = type + '_gross_value'
      const entry_value = type + '_entry_value'
      const hiring_term = type + '_hiring_term'
      const bank_id = type + '_bank_id'
      setDefaultValues(oldDefaultValues => ({
        ...oldDefaultValues,
        [name]: proposalResponse.data[name],
        [cpf]: proposalResponse.data[cpf],
        [birth_date]: proposalResponse.data[birth_date],
        [phone]: proposalResponse.data[phone],
        [cell_phone]: proposalResponse.data[cell_phone],
        [email]: proposalResponse.data[email],
        [marital_status]: proposalResponse.data[marital_status],
        [zip_code]: proposalResponse.data[zip_code],
        [state]: proposalResponse.data[state],
        [district]: proposalResponse.data[district],
        [street]: proposalResponse.data[street],
        [number]: proposalResponse.data[number],
        [city]: proposalResponse.data[city],
        [complement]: proposalResponse.data[complement],
        [fgts_value]: proposalResponse.data[fgts_value],
        [gross_value]: proposalResponse.data[gross_value],
        [entry_value]: proposalResponse.data[entry_value],
        [hiring_term]: proposalResponse.data[hiring_term],
        [bank_id]: proposalResponse.data[bank_id]
      }))
      setIsLoading(false)
    } catch (error) {}
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async ({ value, type }: GetProposalByCpf) => {
    if (value.length === 0) {
      clearTimeout(timeOutId)
      return
    }
    if (value.length < 14) {
      return
    }

    clearTimeout(timeOutId)

    timeOutId = setTimeout(() => getSearched(value, type), 500)
  }

  const validateDate = (value: string, required?: boolean) => {
    if (!value?.length) {
      return true
    }
    const selectedDate = moment().diff(moment(value, dateInputFormat), 'year')
    if (selectedDate >= 18) {
      return true
    }
    return 'Data invalida (Precisa ser maior de idade)'
  }

  const handleCurrentCpf: { [key: string]: string } = {
    seller: customerCpf,
    customer: sellerCpf
  }

  const validateDuplicatedCpf = ({
    value,
    type
  }: {
    value: string
    type: string
  }) => {
    if (!handleCurrentCpf[type]?.includes(value)) {
      return true
    }
    return 'Cpf já utilizado'
  }

  return (
    <>
      <Loading isActive={isLoading} />
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <>
          <div className="row mb-default">
            <Select
              className="col-2"
              label="Produto"
              name="type_product"
              rules={{ required: true }}
              options={PROPOSAL_PRODUCT_TYPE_OPTIONS}
              defaultValue=""
              blank
            />
            <Select
              className="col-2"
              label="Origem da Proposta"
              name="origin_id"
              rules={{ required: false }}
              options={origins}
              onChange={event => {
                if (event.target.value === '2') {
                  setPartnersSearch([{ id: 105, name: 'NeoHome' }])
                  return
                }
                setPartnersSearch([{ id: 0, name: '' }])
              }}
              defaultValue=""
              blank
            />
            {user?.role_id === 1 && (
              <div className="col-6">
                {SearchComponentProduct({
                  index: 0,
                  data: partnersSearch,
                  setData: setPartnersSearch,
                  searchData: partners,
                  selected: selectedPartner,
                  setSelected: setSelectedPartner,
                  label: 'Parceiro',
                  hasFocus,
                  setHasFocus,
                  name: 'partner_id'
                })}
              </div>
            )}
          </div>

          <div className="row mb-default">
            <Title title="Comprador" className="mt-0" />
            <Input
              className="col-2"
              name="customer_cpf"
              label="CPF"
              rules={{
                required: true,

                validate: value =>
                  validateDuplicatedCpf({
                    type: 'customer',
                    value
                  })
              }}
              mask={{
                mask: '999.999.999-99',
                type: 'cpf'
              }}
              onChange={event => {
                const value = event.target.value
                onSearchItem({
                  type: 'customer',
                  value
                })
                setCustomerCpf(value)
              }}
              controlled
            />
            <Input
              className="col-4"
              name="customer_name"
              label="Nome"
              rules={{ required: true }}
            />

            <DatePicker
              name="customer_birth_date"
              className="col-2"
              label="Data de Nascimento"
              placeholderText="DD/MM/AAAA"
              dateFormat="dd/MM/yyyy"
              selected={customerBirthDate}
              maxDate={maxDate}
              rules={{
                required: false,
                validate: validateDate
              }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(dateInputFormat)
                  }}
                  parse={value => {
                    return moment(value, dateInputFormat).toDate()
                  }}
                  validate={(date: string) => {
                    if (date.length === 10) {
                      if (moment(date, dateInputFormat).isAfter(moment())) {
                        return false
                      }
                      const age = moment().diff(
                        moment(date, dateInputFormat),
                        'year'
                      )
                      if (age < 18) {
                        return false
                      }
                    }
                    return true
                  }}
                />
              }
              onChange={date => {
                setCustomerBirthDate(date)
              }}
              controlled
            />
            <Select
              className="col-2"
              label="Estado civil"
              name="customer_marital_status"
              rules={{ required: false }}
              options={OPTIONS_MARITAL_STATUS}
              defaultValue=""
              blank
            />
            <Input
              className="col-2"
              name="customer_cell_phone"
              label="Celular"
              rules={{ required: true }}
              mask={{
                mask: INPUT_MASK.CELL_PHONE
              }}
              controlled
            />
          </div>

          <div className="row mb-default">
            <Input
              className="col-2"
              name="customer_phone"
              label="Telefone"
              mask={{
                mask: INPUT_MASK.PHONE
              }}
              controlled
            />
            <Input
              className="col-4"
              name="customer_email"
              label="E-mail"
              rules={{
                required: false,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
            />
            <Input
              className="col-2"
              name="customer_fgts_value"
              label="Valor FGTS"
              rules={{ required: false }}
              price
            />
            <Input
              className="col-2"
              name="customer_gross_value"
              label="Renda Bruta"
              rules={{ required: false }}
              price
            />
            <Input
              className="col-2"
              name="customer_entry_value"
              label="Valor Financiado"
              rules={{ required: true }}
              price
            />
            <Input
              className="col-2"
              name="customer_hiring_term"
              label="Prazo Contratação"
              rules={{ required: false }}
              mask={{
                mask: '999'
              }}
              controlled
            />
            <Select
              className="col-2"
              label="Banco Correntista"
              name="customer_bank_id"
              rules={{ required: false }}
              options={banks}
              defaultValue={0}
              blank
            />
            <Select
              className="col-2"
              label="Linha de crédito"
              name="customer_credit_line_id"
              rules={{ required: true }}
              options={creditLines}
              defaultValue=""
              blank
            />
          </div>

          <div className="row mb-default">
            <Title CustomTitle={() => <h5>Endereço</h5>} hasSeparator={false} />
            <Input
              className="col-2"
              label="CEP"
              name={prefixCustomer + 'zip_code'}
              mask={{
                mask: '99999-999',
                type: 'zipCode',
                prefixInputsData: prefixCustomer
              }}
              controlled
              rules={{
                required: false,
                minLength: { value: 8, message: 'Cep Inválido' }
              }}
            />
            <Input
              className="col-4"
              label="Logradouro"
              name={prefixCustomer + 'street'}
              rules={{ required: false }}
            />
            <Input
              className="col-2"
              label="Número"
              name={prefixCustomer + 'number'}
              rules={{ required: false }}
            />
            <Input
              className="col-4"
              label="Complemento"
              name={prefixCustomer + 'complement'}
            />
          </div>

          <div className="row mb-default">
            <Input
              className="col-4"
              label="Bairro"
              name={prefixCustomer + 'district'}
              rules={{ required: false }}
            />
            <Input
              className="col-4"
              label="Cidade"
              name={prefixCustomer + 'city'}
              rules={{ required: false }}
            />

            <Select
              className="col-2"
              label="Estado"
              name={prefixCustomer + 'state'}
              rules={{ required: false }}
              options={states}
              defaultValue=""
              blank
            />
          </div>
          <div className="row mb-default">
            <Title title="Pessoas Envolvidas" />
            {peopleInvolvers.length ? (
              peopleInvolvers.map((peopleInvolved, index) => {
                return (
                  <>
                    <div
                      key={peopleInvolved.id}
                      className="row d-flex align-items-end"
                    >
                      <Input
                        className="invisible position-absolute"
                        label=""
                        name={`peoples_involvers.${peopleInvolved.id}.id`}
                        value={`${peopleInvolved.id}`}
                        controlled
                      />
                      <Input
                        className="col-4"
                        customLabel={() => (
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="name"
                              className="col-form-label fw-bold fs-6"
                            >
                              Nome
                            </label>
                            <span
                              className="ms-2 fa fa-trash text-danger"
                              onClick={() => {
                                setPeopleInvolved(oldPeoples => {
                                  const newPeoples = oldPeoples.filter(
                                    people =>
                                      Number(people.id) !==
                                      Number(peopleInvolved.id)
                                  )
                                  return newPeoples
                                })
                              }}
                            ></span>
                          </div>
                        )}
                        name={`peoples_involvers.${peopleInvolved.id}.name`}
                        rules={{ required: true }}
                        onChange={event => {
                          setPeopleInvolved(oldPeoples => {
                            const copyOldPeople = [...oldPeoples]
                            copyOldPeople[index].name = event.target.value
                            return copyOldPeople
                          })
                        }}
                        controlled
                      />

                      <Input
                        className="col-2"
                        name={`peoples_involvers.${peopleInvolved.id}.cell_phone`}
                        label="Celular"
                        rules={{ required: false }}
                        mask={{
                          mask: INPUT_MASK.CELL_PHONE
                        }}
                        onChange={event => {
                          setPeopleInvolved(oldPeoples => {
                            const copyOldPeople = [...oldPeoples]
                            copyOldPeople[index].cell_phone = event.target.value
                            return copyOldPeople
                          })
                        }}
                        controlled
                      />

                      <Input
                        className="col-4"
                        name={`peoples_involvers.${peopleInvolved.id}.email`}
                        label="E-mail"
                        rules={{
                          required: false,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email inválido'
                          }
                        }}
                        onChange={event => {
                          setPeopleInvolved(oldPeoples => {
                            const copyOldPeople = [...oldPeoples]
                            copyOldPeople[index].email = event.target.value
                            return copyOldPeople
                          })
                        }}
                        controlled
                      />
                      <Select
                        className="col-2"
                        name={`peoples_involvers.${peopleInvolved.id}.indication_type`}
                        label="Tipo de Indicação"
                        rules={{
                          required: false
                        }}
                        onChange={event => {
                          setPeopleInvolved(oldPeoples => {
                            const copyOldPeople = [...oldPeoples]
                            copyOldPeople[index].indication_type =
                              event.target.value
                            return copyOldPeople
                          })
                        }}
                        options={indicationTypes}
                        blank
                        controlled
                      />
                    </div>
                    <div className="row">
                      {index === 0 && (
                        <div className="mt-5 col-1 d-flex align-items-end">
                          <button
                            className="btn btn-primary "
                            type="button"
                            onClick={() => {
                              const nextId =
                                Number(lastId) === undefined
                                  ? 0
                                  : Number(lastId) + 1
                              setLastId(nextId)
                              setPeopleInvolved([
                                ...peopleInvolvers,
                                {
                                  id: nextId
                                }
                              ])
                            }}
                          >
                            Adicionar
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )
              })
            ) : (
              <div className="col-1 d-flex align-items-end">
                <button
                  className="btn btn-primary "
                  type="button"
                  onClick={() => {
                    const nextId = 0
                    setLastId(nextId)
                    setPeopleInvolved([
                      {
                        id: nextId
                      }
                    ])
                  }}
                >
                  Adicionar
                </button>
              </div>
            )}
          </div>

          <div className="row mb-default">
            <Title title="Imóvel" />

            <Select
              className="col-2"
              label="Tipo"
              name="immobile_type"
              rules={{ required: false }}
              options={OPTIONS_IMMOBILE_TYPES}
              defaultValue=""
              blank
            />
            <Select
              className="col-2"
              label="Situação"
              name="immobile_situation"
              rules={{ required: false }}
              options={OPTIONS_IMMOBILE_SITUATION}
              defaultValue=""
              blank
            />

            <Input
              className="col-4"
              name="immobile_registry"
              label="Cartório"
              rules={{ required: false }}
            />

            <Input
              className="col-2"
              name="immobile_registration"
              label="Matricula"
              rules={{ required: false }}
            />
            <Input
              className="col-2"
              name="immobile_value"
              label="Valor"
              rules={{ required: false }}
              price
            />
          </div>

          <div className="row mb-default">
            <Input
              className="col-2"
              name="iptu_cadastral_index"
              label="Índice cadastral do IPTU"
              rules={{ required: false }}
            />
            <Input
              className="col-2"
              label="CEP"
              name="immobile_zip_code"
              mask={{
                mask: '99999-999',
                type: 'zipCode',
                prefixInputsData: 'immobile_'
              }}
              controlled
              rules={{
                required: false,
                minLength: { value: 8, message: 'Cep Inválido' }
              }}
            />
            <Input
              className="col-4"
              label="Logradouro"
              name="immobile_street"
              rules={{ required: false }}
            />

            <Input
              className="col-4"
              label="Complemento"
              name="immobile_complement"
            />
          </div>

          <div className="row mb-default">
            <Input
              className="col-2"
              label="Número"
              name="immobile_number"
              rules={{ required: false }}
            />
            <Input
              className="col-4"
              label="Bairro"
              name="immobile_district"
              rules={{ required: false }}
            />
            <Input
              className="col-4"
              label="Cidade"
              name="immobile_city"
              rules={{ required: false }}
            />

            <Select
              className="col-2"
              label="Estado"
              name="immobile_state"
              rules={{ required: false }}
              options={states}
              defaultValue=""
              blank
            />
          </div>
          <div className="row mb-default">
            <Title title="Vendedor" className="mt-0" />
            <Input
              className="col-2"
              name="seller_cpf"
              label="CPF"
              rules={{
                required: false,
                validate: value =>
                  validateDuplicatedCpf({
                    type: 'seller',
                    value
                  })
              }}
              mask={{
                mask: '999.999.999-99',
                type: 'cpf'
              }}
              onChange={event => {
                const value = event.target.value
                onSearchItem({
                  type: 'seller',
                  value
                })
                setSellerCpf(value)
              }}
              controlled
            />
            <Input
              className="col-4"
              name="seller_name"
              label="Nome"
              rules={{ required: false }}
            />
            <DatePicker
              name="seller_birth_date"
              className="col-2"
              label="Data de Nascimento"
              placeholderText="DD/MM/AAAA"
              dateFormat="dd/MM/yyyy"
              selected={sellerBirthDate}
              maxDate={maxDate}
              rules={{
                required: false,
                validate: event => validateDate(event, false)
              }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(dateInputFormat)
                  }}
                  parse={value => {
                    return moment(value, dateInputFormat).toDate()
                  }}
                  validate={(date: string) => {
                    if (date.length === 10) {
                      if (moment(date, dateInputFormat).isAfter(moment())) {
                        return false
                      }
                      const age = moment().diff(
                        moment(date, dateInputFormat),
                        'year'
                      )
                      if (age < 18) {
                        return false
                      }
                    }
                    return true
                  }}
                />
              }
              onChange={date => {
                setSellerBirthDate(date)
              }}
              controlled
            />
            <Select
              className="col-2"
              label="Estado civil"
              name="seller_marital_status"
              rules={{ required: false }}
              options={OPTIONS_MARITAL_STATUS}
              defaultValue=""
              blank
            />
            <Input
              className="col-2"
              name="seller_cell_phone"
              label="Celular"
              rules={{
                required: false,
                minLength: { message: 'Número Invalido', value: 14 }
              }}
              mask={{
                mask: INPUT_MASK.CELL_PHONE
              }}
              controlled
            />
          </div>
          <div className="row mb-default">
            <Input
              className="col-2"
              name="seller_phone"
              label="Telefone"
              mask={{
                mask: INPUT_MASK.PHONE
              }}
              controlled
            />
            <Input
              className="col-4"
              name="seller_email"
              label="E-mail"
              rules={{
                required: false,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
            />
          </div>

          <div className="row mb-default">
            <Title CustomTitle={() => <h5>Endereço</h5>} hasSeparator={false} />
            <Input
              className="col-2"
              label="CEP"
              name={prefixSeller + 'zip_code'}
              mask={{
                mask: '99999-999',
                type: 'zipCode',
                prefixInputsData: prefixSeller
              }}
              controlled
              rules={{
                required: false,
                minLength: { value: 8, message: 'Cep Inválido' }
              }}
            />
            <Input
              className="col-4"
              label="Logradouro"
              name={prefixSeller + 'street'}
              rules={{ required: false }}
            />
            <Input
              className="col-2"
              label="Número"
              name={prefixSeller + 'number'}
              rules={{ required: false }}
            />
            <Input
              className="col-4"
              label="Complemento"
              name={prefixSeller + 'complement'}
            />
          </div>

          <div className="row mb-default">
            <Input
              className="col-4"
              label="Bairro"
              name={prefixSeller + 'district'}
              rules={{ required: false }}
            />
            <Input
              className="col-4"
              label="Cidade"
              name={prefixSeller + 'city'}
              rules={{ required: false }}
            />

            <Select
              className="col-2"
              label="Estado"
              name={prefixSeller + 'state'}
              rules={{ required: false }}
              options={states}
              defaultValue=""
              blank
            />
          </div>

          <div className="card-footer d-flex justify-content-end px-0 mt-5">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </>
      </Form>
    </>
  )
}
