import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiCreatePerImport, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { AddHiringForm } from 'components/AddHiringForm'
import { useAuth } from 'hooks/auth'
import { Title } from 'components/Title'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type HiringData = {
  id?: number
  name?: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: HiringData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormCreatePerImportHiring = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { user } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const onSubmitForm = async (data: any) => {
    console.log(data)
    const formData = new FormData()
    formData.append('file', data.file[0])
    try {
      activeLoading()
      try {
        await api.post(apiCreatePerImport(), formData)
        disableLoading()
        updateDataTable()
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Registro criado com sucesso'
        })
        history.push(nameActions.read.to)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
        updateDataTable()
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <div className="row mb-default">
        <Title title="Informações" className="mt-0" />
        <Input
          className="col-6"
          name="file"
          label="Arquivo"
          type="file"
          rules={{ required: true }}
          accept=".csv"
        />
      </div>
      <div className="card-footer d-flex justify-content-end px-0 mt-5">
        <button type="submit" className="btn btn-primary">
          Salvar
        </button>
      </div>
    </Form>
  )
}
