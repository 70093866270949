import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/IndicationTypes/List'
import Create from '../../pages/Commercial/IndicationTypes/Create'
import View from '../../pages/Commercial/IndicationTypes/View'
import Update from '../../pages/Commercial/IndicationTypes/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const IndicationTypesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/indicationTypes" exact component={List} />
    <PrivateRoutes
      path="/commercial/indicationTypes/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/indicationTypes/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/indicationTypes/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
