import React from 'react'

import { Container, InconClose } from './style'

type AlertProps = {
  message?: string
  isActive: boolean
  onClickConfirmButton: (id: string) => void
  onClickCancellButton?: () => void
  RenderComponent?: () => JSX.Element
  onlyConfirm?: boolean
  title?: string
  textConfim?: string
  textCancel?: string
}

export const Alert = ({
  message,
  onClickCancellButton,
  onClickConfirmButton,
  isActive,
  onlyConfirm,
  RenderComponent,
  title,
  textConfim,
  textCancel
}: AlertProps): JSX.Element => {
  return (
    <Container isActive={isActive}>
      <main>
        <InconClose
          onClick={() => {
            if (onClickCancellButton) onClickCancellButton()
          }}
        />
        <h4>{title || 'Atenção'}</h4>
        <hr />
        {RenderComponent && <RenderComponent />}
        <h4 style={{ fontWeight: 500 }}>{message}</h4>
        <hr />
        <footer>
          {!onlyConfirm && (
            <button
              type="button"
              className="btn btn-warning  text-center"
              onClick={onClickCancellButton}
            >
              {textCancel || 'Cancelar'}
            </button>
          )}
          <button
            type="button"
            className="btn  btn-primary "
            onClick={() => {
              onClickConfirmButton('')
            }}
          >
            {textConfim || 'Ok'}
          </button>
        </footer>
      </main>
    </Container>
  )
}
