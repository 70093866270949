import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Origins/List'
import Create from '../../pages/Commercial/Origins/Create'
import View from '../../pages/Commercial/Origins/View'
import Update from '../../pages/Commercial/Origins/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const OriginsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/origins" exact component={List} />
    <PrivateRoutes path="/commercial/origins/create" exact component={Create} />
    <PrivateRoutes path="/commercial/origins/view/:id" exact component={View} />
    <PrivateRoutes
      path="/commercial/origins/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
