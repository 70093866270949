/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, nameEntity } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { Title } from 'components/Title'
import DataTable from 'components/DataTable'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import Modal from '../../../../components/Modal'
import { FormFile } from '../components/FormFile'
import { useAuth } from 'hooks/auth'
import { useUpdateDataTable } from 'hooks/dataTable'
import { FormSimulation } from '../components/FormSimulation'

interface ProposalData {
  id?: number
  partner_id: number
  seller_name: string
  seller_cpf: string
  seller_rg: string
  seller_birth_date: Date
  seller_phone: string
  seller_cell_phone: string
  seller_email: string
  seller_type_account: string
  seller_bank_code: string
  seller_marital_status: string
  seller_agency: string
  seller_account: string
  seller_account_digit: string
  seller_zip_code: string
  seller_state: string
  seller_district: string
  seller_street: string
  seller_number: string
  seller_city: string
  seller_complement?: string
  customer_marital_status: number
  customer_zip_code: string
  customer_state: string
  customer_district: string
  customer_street: string
  customer_number: string
  customer_city: string
  customer_complement?: string
  customer_name: string
  customer_cpf: string
  customer_rg: string
  customer_birth_date: Date
  customer_phone: string
  customer_cell_phone: string
  customer_email: string
  customer_fgts_value: number
  customer_gross_value: number
  customer_entry_value: number
  customer_hiring_term: number
  customer_bank_id: number
  immobile_type: 'Home' | 'Apartment'
  immobile_situation: 'New' | 'Used'
  immobile_registry: string
  immobile_registration: string
  immobile_value: string
  immobile_zip_code: string
  immobile_state: string
  immobile_district: string
  immobile_street: string
  immobile_number: string
  immobile_city: string
  immobile_complement?: string
  iptu_cadastral_index?: string
  next_service?: string
  created_at?: string
  updated_at?: string
  peoples_involvers?: any
  partner?: {
    name: string
  }
  bank?: {
    name: string
  }
  statusProposal?: {
    name: string
  }
  createdUser?: { name: string }
  origin?: { name: string }
  creditLine?: { name: string }
}

const View = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const { user } = useAuth()
  const [proposal, setProposal] = useState<ProposalData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const isPartner = useMemo(() => {
    return user.role_id === 2
  }, [user.role_id])

  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])
  const searchParametersProposalStatus = useRef([{ proposal_id: id }])
  const { activeLoading, disableLoading } = useLoading()
  const [modalCreate, setModalCreate] = useState(false)
  const [modalCreateSimulation, setModalCreateSimulation] = useState(false)
  const [peopleInvolvers, setPeopleInvolved] = useState<
    {
      id?: number
      name?: string
      email?: string
      indication_type?: string
      cell_phone?: string
    }[]
  >([])
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any>({} as any)
  const [removeItem, setRemoveItem] = useState<any>({} as any)
  const [currentItemSimulationUpdate, setCurrentItemSimulationUpdate] =
    useState<any>({} as any)

  const [modalEdit, setModalEdit] = useState(false)
  const [modalEditSimulation, setModalEditSimulation] = useState(false)

  async function loadProposal(): Promise<void> {
    activeLoading()
    try {
      const response = await api.get<ProposalData>(apiList(id))
      const { data } = response
      setProposal(data)
      if (data.peoples_involvers) {
        const filterPeoplesInvolved = data.peoples_involvers.filter(
          (peopleInvolved: any) => peopleInvolved
        )
        setPeopleInvolved([...filterPeoplesInvolved])
      }
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar a prospecção',
        description:
          'Houve um error ao carregar a prospecção, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }
  useEffect(() => {
    loadProposal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)
  const [alertRemoveParentSimulation, setAlertRemoveParentSimulation] =
    useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }
  const handleOnClickRemoveParentSimulation = (item: any) => {
    setAlertRemoveParentSimulation(true)
    setRemoveItem(item)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(`/commercial/proposals/delete/${id}`)
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Proposta removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Simulação não removido, pois ainda está sendo usada.'
      })
    }
  }
  const handlerOnClickButtonConfirmRemoveParentSimulation = async () => {
    try {
      await api.delete(
        `/commercial/proposalSimulations/delete/${removeItem?.id}`
      )
      setAlertRemoveParentSimulation(false)
      addToast({
        type: 'success',
        title: 'Simulação removido com sucesso.'
      })
      updateDataTable()
      // history.goBack()
    } catch (err: any) {
      setAlertRemoveParentSimulation(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Simulação não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
    setAlertRemoveParentSimulation(false)
  }
  const handleClickOnClose = async () => {
    setModalCreate(false)
    setModalCreateSimulation(false)
    setModalEdit(false)
    setModalEditSimulation(false)
    await loadProposal()
  }
  const handleClickOnOpenModalCreate = () => {
    setModalCreate(true)
  }
  const handleClickOnOpenModalCreateSimulation = () => {
    setModalCreateSimulation(true)
  }

  const handleNameOfFile = (item: { filename: string }) => {
    let filename = item?.filename
    const indexTrace = filename.indexOf('-') + 1
    filename = filename.slice(indexTrace, filename.length)
    return filename
  }
  const handleDownload = useCallback(
    (item: { filename: string; url: string }) => {
      const filename = handleNameOfFile(item)
      fetch(item?.url).then(response => {
        response.blob().then((blobObject: any) => {
          const url = window.URL.createObjectURL(blobObject)
          const a = document.createElement('a')
          a.download = filename
          a.href = url
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
      })
    },
    []
  )

  const handlerOnClickButtonEditInCurrentRow = (currentValue: any) => {
    setCurrentItemUpdate(currentValue)
    setModalEdit(true)
  }

  const handlerOnClickButtonEditInCurrentRowSimulation = (
    currentValue: any
  ) => {
    setCurrentItemSimulationUpdate({ ...currentValue, proposal })
    setModalEditSimulation(true)
  }

  const handleDuplicatedRow = async (currentValue: any) => {
    activeLoading()
    try {
      const formData = new FormData()
      for (const file of currentValue.files) {
        const blob = await fetch(file.url).then(r => r.blob())
        const filename = handleNameOfFile(file)
        const newFile = new File([blob], filename)
        formData.append('file', newFile)
      }
      formData.append('proposal_id', currentValue.proposal_id)
      formData.append('status_id', currentValue.status_id)
      formData.append('observations', currentValue.observations)
      await api.post('/commercial/proposalsStatus/create', formData)
      updateDataTable()
    } catch (error) {}
    disableLoading()
  }

  const toolsContainer = useMemo(() => {
    let tools = [
      toolsViewUpdate(String(id)),
      toolsViewDelete(() => {
        handleOnClickRemoveParent()
      }),
      toolsViewCreate(),
      toolsViewList()
    ]
    if (isPartner) {
      tools = [toolsViewUpdate(String(id)), toolsViewCreate(), toolsViewList()]
    }
    return tools
  }, [id, isPartner])

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={toolsContainer}
      >
        <div className="form-body">
          <div className="row mb-default">
            <Title title="Detalhes" className="mt-0" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód.
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.id}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Parceiro
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.partner?.name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Origem
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.origin?.name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Status
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.statusProposal?.name}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Próximo Atendimento
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.next_service}</p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Comprador" />
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.customer_name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  CPF
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.customer_cpf}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Data de Nascimento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_birth_date}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado civil
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_marital_status}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Celular
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_cell_phone}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Telefone
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_phone}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_email}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Valor FGTS
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_fgts_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Renda Bruta
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_gross_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Valor Entrada
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_entry_value}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Prazo Contratação
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_hiring_term}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Banco Correntista
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.bank?.name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Linha de crédito
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.creditLine?.name}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  CEP
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_zip_code}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Logradouro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_street}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Número
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_number}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Complemento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_complement}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Bairro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_district}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cidade
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.customer_city}</p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_state}
                </p>
              </div>
            </div>
          </div>

          {!!peopleInvolvers.length && (
            <div className="row mb-default">
              <Title title="Pessoas Envolvidas" />
              {peopleInvolvers.map(peopleInvolved => (
                <div className="row" key={peopleInvolved.id}>
                  <div className="col-4">
                    <div className="form-group">
                      <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                        Nome
                      </label>
                      <p className=" fs-6 text-gray-800">
                        {peopleInvolved?.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label className="col-form-label fw-bold fs-6 form-label">
                        Celular
                      </label>
                      <p className=" fs-6 text-gray-800">
                        {peopleInvolved?.cell_phone}
                      </p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                        Email
                      </label>
                      <p className=" fs-6 text-gray-800">
                        {peopleInvolved?.email}
                      </p>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                        Tipo de indicação
                      </label>
                      <p className=" fs-6 text-gray-800">
                        {peopleInvolved?.indication_type}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="row mb-default">
            <Title title="Imóvel" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.immobile_type}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  Situação
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_situation}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cartório
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_registry}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Matricula
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_registration}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Valor
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Índice cadastral do IPTU
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.iptu_cadastral_index}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  CEP
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_zip_code}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Logradouro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_street}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Número
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_number}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Complemento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_complement}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Bairro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_district}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cidade
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.immobile_city}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_state}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Informações Adicionais" />
            <div className="col-2">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Criador por
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.createdUser?.name}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cadastrado em
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Atualizado em
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.updated_at}</p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <Title title="Vendedor" />
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  CPF
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_cpf}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Data de Nascimento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_birth_date}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado civil
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_marital_status}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Celular
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_cell_phone}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Telefone
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_phone}</p>
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_email}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  CEP
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_zip_code}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Logradouro
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_street}</p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Número
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_number}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Complemento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_complement}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Bairro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_district}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-default">
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cidade
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_city}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_state}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="">
            <Tabs>
              <Tab key={0} title="Status">
                <div
                  className="p-9"
                  style={{ pointerEvents: isPartner ? 'none' : 'all' }}
                >
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalCreate}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Adicionar</span>
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      // entityId={id}
                      format={{ orderBy: 'id' }}
                      orderBySort="DESC"
                      source="proposalStatus"
                      entity="ProposalStatus"
                      headers={[
                        {
                          name: 'Data',
                          field: 'created_at',
                          sortable: true
                        },
                        {
                          name: 'Próximo Atendimento',
                          field: 'next_service',
                          sortable: true
                        },
                        {
                          name: 'Status',
                          field: 'statusProposal.name',
                          sortable: true
                        },
                        {
                          name: 'Observações',
                          field: 'observations',
                          sortable: true
                        },
                        {
                          name: 'Anexos',
                          field: 'files',
                          sortable: false,
                          custom: true
                        },
                        {
                          name: 'Usuário',
                          field: 'createdUser.name',
                          sortable: false
                        },
                        {
                          name: 'Ações',
                          field: 'actions',
                          sortable: false
                        }
                      ]}
                      customHeaders={[
                        {
                          name: 'Anexos',
                          field: 'files',
                          sortable: false,
                          element: item => {
                            const files = item?.files

                            return (
                              <div>
                                {files?.map((file: any) => (
                                  <div
                                    key={file.filename}
                                    onClick={() => handleDownload(file)}
                                    className="d-flex align-items-center text-primary cursor-pointer"
                                  >
                                    <span className="fa fa-download me-2"></span>
                                    <p>{file.filename}</p>
                                  </div>
                                ))}
                              </div>
                            )
                          }
                        },
                        {
                          name: 'Ações',
                          field: 'actions',
                          sortable: false,
                          element: () => (
                            <div>
                              <p></p>
                            </div>
                          )
                        }
                      ]}
                      actions={[
                        {
                          name: 'Duplicar',
                          title: 'Duplicar',
                          // spanIcon: 'fa fa-refresh',
                          onClick: subMenu => {
                            handleDuplicatedRow(subMenu)
                          },
                          element: item => {
                            const verifyIfUserIsSameCreated =
                              Number(item.created_by) === Number(user.id)
                            const verifyIfItemIsMostRecent =
                              Number(item.id) === Number(item.recentItemId)
                            if (
                              verifyIfUserIsSameCreated &&
                              verifyIfItemIsMostRecent
                            ) {
                              return (
                                <span
                                  className="fa fa-refresh"
                                  onClick={item => handleDuplicatedRow(item)}
                                ></span>
                              )
                            }
                            return <div></div>
                          }
                        },
                        {
                          name: 'Editar',
                          title: 'Editar',
                          element: item => {
                            const verifyIfUserIsSameCreated =
                              Number(item.created_by) === Number(user.id)
                            const verifyIfItemIsMostRecent =
                              Number(item.id) === Number(item.recentItemId)
                            if (
                              verifyIfUserIsSameCreated &&
                              verifyIfItemIsMostRecent
                            ) {
                              return (
                                <span
                                  className="fa fa-edit"
                                  onClick={() =>
                                    handlerOnClickButtonEditInCurrentRow({
                                      ...item,
                                      next_service: proposal?.next_service
                                    })
                                  }
                                ></span>
                              )
                            }
                            return <div></div>
                          }
                        }
                      ]}
                      searchParameters={searchParametersProposalStatus.current}
                    />
                  </div>
                </div>
              </Tab>
              <Tab key={1} title="Simulações">
                <div
                  className="p-9"
                  style={{ pointerEvents: isPartner ? 'none' : 'all' }}
                >
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalCreateSimulation}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Adicionar</span>
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      format={{ orderBy: 'id' }}
                      orderBySort="DESC"
                      source="proposalSimulation"
                      entity="ProposalSimulation"
                      headers={[
                        {
                          name: 'Banco',
                          field: 'bank.name',
                          sortable: true
                        },
                        {
                          name: 'Valor Financiado',
                          field: 'financed_amount',
                          sortable: true
                        },
                        {
                          name: 'Taxa de Juros',
                          field: 'interest_amount',
                          sortable: false
                        },
                        {
                          name: 'Valor Parcela',
                          field: 'installment_value',
                          sortable: false
                        },
                        {
                          name: 'Prazo',
                          field: 'term',
                          sortable: false
                        },
                        {
                          name: 'Status',
                          field: 'status',
                          sortable: false
                        },
                        {
                          name: 'Criado em',
                          field: 'created_at',
                          sortable: true
                        },
                        {
                          name: 'Ações',
                          field: 'actions',
                          sortable: false
                        }
                      ]}
                      actions={[
                        {
                          name: 'Editar',
                          title: 'Editar',
                          spanIcon: 'fa fa-edit',
                          onClick: subMenu => {
                            handlerOnClickButtonEditInCurrentRowSimulation(
                              subMenu
                            )
                          }
                        },
                        {
                          name: 'Remover',
                          title: 'Remover',
                          spanIcon: 'fa fa-times',
                          onClick: subMenu => {
                            handleOnClickRemoveParentSimulation(subMenu)
                          }
                        }
                      ]}
                      searchParameters={searchParametersProposalStatus.current}
                    />
                  </div>
                </div>
              </Tab>
              <Tab key={2} title="Histórico">
                <div
                  className="p-9"
                  style={{ pointerEvents: isPartner ? 'none' : 'all' }}
                >
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    {/* <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalQuery}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Consultar Banco</span>
                    </div> */}
                  </div>
                  <div className="">
                    <DataTable
                      entityId={id}
                      format={{ orderBy: '' }}
                      source="auditLogs"
                      entity="AuditLog"
                      searchParameters={searchParametersAuditLog.current}
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${proposal?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(proposal?.id))
        }
        isActive={alertRemoveParent}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${removeItem?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParentSimulation()
        }
        isActive={alertRemoveParentSimulation}
      />
      {modalCreateSimulation && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalCreateSimulation}
          pageTitle="Adicionar Simulação"
          styles={{ padding: '30px 350px' }}
          Children={
            <FormSimulation
              typeForm="create"
              initialValues={{ financed_amount: proposal.customer_entry_value }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
      {modalCreate && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalCreate}
          pageTitle="Atualização de Status"
          styles={{ padding: '30px 250px' }}
          Children={
            <FormFile
              typeForm="create"
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
              initialValues={proposal}
            />
          }
        />
      )}
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={'Editar'}
          styles={{ padding: '30px 250px' }}
          Children={
            <FormFile
              typeForm="update"
              initialValues={{
                ...currentItemUpdate
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
      {modalEditSimulation && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEditSimulation}
          pageTitle={'Editar'}
          styles={{ padding: '30px 350px' }}
          Children={
            <FormSimulation
              typeForm="update"
              initialValues={{
                ...currentItemSimulationUpdate
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
    </>
  )
}

export default View
