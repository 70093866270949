import React from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
const List: React.FC = () => {
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        entity={nameEntity}
        source={nameSource}
        format={{ orderBy: 'name' }}
        notHasChildren
        headers={headers}
      />
    </Container>
  )
}
export default List
