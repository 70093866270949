import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
import { FilterContaier } from './styles'
import { Date as DatePicker } from '../../../../components/Form/date'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { SearchComponentProduct } from '../components/SearchComponentProduct'
import { Select } from '../../../../components/Form'
import { OPTIONS_TYPE_DATE_PROPOSAL_FILTER } from 'common/constants'
import { useAuth } from 'hooks/auth'
import { StringParam, useQueryParam } from 'use-query-params'
import moment from 'moment'
import { Loading } from 'components/Loading'
const List: React.FC = () => {
  const { activeLoading, disableLoading } = useLoading()
  const { user } = useAuth()
  const { addToast } = useToast()
  const [startDateQuery] = useQueryParam('start_date', StringParam)
  const [endDateQuery] = useQueryParam('end_date', StringParam)
  const [partners, setPartners] = useState<any[]>([])
  const [parameters, setParameters] = useState<any>([])
  const [status, setStatus] = useState<any[]>([])
  const [statusValue, setStatusValue] = useState<string>('')
  const [filterDateBy, setFilterDateBy] = useState<string>('next_service')
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [isLoading, setIsLoading] = useState(true)
  const [partnersSearch, setPartnersSearch] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [selectedPartner, setSelectedPartner] = useState([
    { id: 0, selected: false }
  ])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])

  const loadPartners = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/commercial/partners')
      setPartners(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])
  const loadStatus = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/commercial/statusProposals')
      setStatus(
        data.map(
          (item: { id: number; name: string }) =>
            item && { name: item.name, value: item.id }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])
  useEffect(() => {
    loadPartners()
    loadStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setParameters([
      {
        startDate: startDateQuery,
        endDate: endDateQuery,
        fieldDate: filterDateBy
      }
    ])
    if (startDateQuery) {
      setStartDate(moment(startDateQuery).toDate())
    }
    if (endDateQuery) {
      setEndDate(moment(endDateQuery).toDate())
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [endDateQuery, filterDateBy, startDateQuery])
  const handleGenerateExport = useCallback(async () => {
    try {
      const responseProposals = await api.post(
        '/commercial/proposals/generateExport',
        {
          status_id: statusValue || undefined,
          start_date: startDate,
          end_date: endDate,
          filter_date_by: filterDateBy,
          partner_id:
            user.role_id === 1 ? selectedPartner[0]?.id || undefined : user.uid
        }
      )
      const blob = new Blob(['\ufeff' + responseProposals.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = 'relatório-contratacoes.csv'
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error ao baixar o relatório',
        description:
          'Houve um error ao baixar o relatório de contratações, tente novamente mais tarde!'
      })
      console.log(error)
    }
  }, [addToast, endDate, selectedPartner, startDate, statusValue, filterDateBy])

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContaier>
        <div className="row">
          <div className="col-md-3">
            <DatePicker
              name="start_date"
              label="Data de início"
              selected={startDate}
              onChange={e => {
                if (endDate) {
                  if (e > endDate) {
                    setEndDate(null)
                  }
                }
                setStartDate(e)
              }}
              controlled
            />
          </div>
          <div className="col-md-3">
            <DatePicker
              name="end_date"
              label="Data de término"
              selected={endDate}
              minDate={startDate}
              onChange={e => setEndDate(e)}
              controlled
            />
          </div>
          <div className="col-md-3">
            <Select
              name=""
              label="Filtrar Data Por"
              value={filterDateBy}
              onChange={({ target }) => setFilterDateBy(target.value)}
              options={OPTIONS_TYPE_DATE_PROPOSAL_FILTER}
              blank
            />
          </div>
          <div className="col-md-3">
            {SearchComponentProduct({
              index: 0,
              data: partnersSearch,
              setData: setPartnersSearch,
              searchData: partners,
              selected: selectedPartner,
              setSelected: setSelectedPartner,
              label: 'Parceiro',
              hasFocus,
              setHasFocus,
              name: 'partner_id'
            })}
          </div>
          <div className="col-md-3">
            <Select
              name="status"
              label="Status"
              id="status"
              value={statusValue}
              onChange={({ target }) => setStatusValue(target.value)}
              options={status}
              blank
            />
          </div>
        </div>
        <footer>
          <button
            className="btn btn-light-primary"
            onClick={handleGenerateExport}
          >
            EXPORTAR
          </button>
          <button
            className="btn btn-light-primary"
            onClick={() => {
              setStatusValue('')
              setStartDate(null)
              setEndDate(null)
              setParameters([])
              setSelectedPartner([{ id: 0, selected: false }])
              setPartnersSearch([{ id: 0, name: '' }])
              setFilterDateBy('next_service')
            }}
          >
            LIMPAR
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setParameters([
                {
                  status: statusValue || undefined,
                  startDate,
                  endDate,
                  partner_id: selectedPartner[0]?.id || undefined,
                  fieldDate: filterDateBy
                }
              ])
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr className="divider" />
      </FilterContaier>
      <DataTable
        entity={nameEntity}
        source={nameSource}
        format={{ orderBy: 'customer_name' }}
        notHasChildren
        headers={headers}
        searchParameters={[
          {
            ...parameters[0],
            partner_id:
              user.role_id === 1
                ? parameters[0]?.partner_id || undefined
                : user.uid
          }
        ]}
      />
    </Container>
  )
}
export default List
