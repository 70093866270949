import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PageLink, PageTitle } from '../../assets/layout/core'
import { CardSimple } from 'components/CardSimple'
import {
  faArrowCircleRight,
  faFile,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import api from 'services/api'
import { useAuth } from 'hooks/auth'
import { PARTNER_STATUS_ENUM } from 'common/constants/partner'
import moment from 'moment'
import { DATE_FORMATS, INPUT_MASK, LOCAL_STORAGE_KEYS } from 'common/constants'
import { HIRING_STATUS_ENUM_NUMBER } from 'common/constants/hiring-status'
import { Loading } from 'components/Loading'
import Modal from 'components/Modal'
import axios from 'axios'
import { getFileName } from 'utlis/getFileName'

type ReportDashboard = {
  partnersAwaitingSign: number
  partnersAwaitingReleased: number
  partnersActive: number
  proposalNextServiceToday: number
  proposalNextServiceWeek: number
  hiringFormalizedPerMonth: number
  hiringInProgressPerMonth: number
}

const startTodayDate = moment().startOf('D').format(DATE_FORMATS.ISO)
const endTodayDate = moment().endOf('D').format(DATE_FORMATS.ISO)
const startWeekDate = moment().startOf('W').format(DATE_FORMATS.ISO)
const endWeekDate = moment().endOf('W').format(DATE_FORMATS.ISO)
const startMonthDate = moment().startOf('M').format(DATE_FORMATS.ISO)
const endMonthDate = moment().endOf('M').format(DATE_FORMATS.ISO)

const handleDownload = async ({
  file_url,
  file
}: {
  file: string
  file_url: string
}) => {
  fetch(file_url).then(response => {
    response.blob().then((blobObject: any) => {
      const url = window.URL.createObjectURL(blobObject)
      const a = document.createElement('a')
      a.download = getFileName({ fileName: file })
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  })
}

const ContainerFileWarning = ({
  file_url,
  file,
  type
}: {
  file_url: string
  file: string
  type: warningsTypes
}) => {
  if (type === 'show') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={file_url} height={600} width="auto" />
      </div>
    )
  }
  if (type === 'download') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            handleDownload({ file_url, file })
          }}
        >
          Baixar arquivo
        </button>
      </div>
    )
  }
  return null
}

const ContainerWarning = ({
  description,
  file,
  file_url,
  type
}: warningsApiResponse) => {
  return (
    <div>
      <div className="mb-5">
        <p className="col-form-label fw-bold fs-6">{description}</p>
      </div>
      <div>
        <ContainerFileWarning file={file} file_url={file_url} type={type} />
      </div>
    </div>
  )
}

const Dashboard: React.FC = () => {
  const { user } = useAuth()
  const [hiringFormalizedPerMonth, setHiringFormalizedPerMonth] = useState(0)
  const [hiringInProgressPerMonth, setHiringInProgressPerMonth] = useState(0)
  const [proposalNextServiceWeek, setProposalNextServiceWeek] = useState(0)
  const [proposalNextServiceToday, setProposalNextServiceToday] = useState(0)
  const [contractsAwaitingSign, setContractsAwaitingSign] = useState(0)
  const [partnersAwaitingReleased, setPartnersAwaitingSign] = useState(0)
  const [partnersActive, setPartnersActive] = useState(0)
  const [warning, setWarning] = useState<warningsApiResponse | undefined>()
  const [modalWarning, setModalWarning] = useState(false)
  const [isLoadingCards, setIsLoadingCards] = useState(false)
  const [isLoadingWarning, setIsLoadingWarning] = useState(false)
  const dashboardBreadCrumbs: PageLink[] = useMemo(
    () => [
      {
        title: 'Início',
        path: '/dashboard',
        isSeparator: false,
        isActive: false
      }
    ],
    []
  )
  const loadWarning = useCallback(async () => {
    if (user.role_id !== 2) {
      return
    }
    setIsLoadingCards(true)
    try {
      const responseWarning = await api.get('/warnings/lastValid')
      setWarning(responseWarning.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingWarning(false)
      setModalWarning(true)
    }
  }, [user?.role_id])
  const loadDashboardCards = useCallback(async () => {
    setIsLoadingCards(true)
    try {
      const responseReportDashboard = await api.get<ReportDashboard>(
        '/commercial/partners/reportDashboard'
      )
      setHiringInProgressPerMonth(
        responseReportDashboard.data.hiringInProgressPerMonth
      )
      setHiringFormalizedPerMonth(
        responseReportDashboard.data.hiringFormalizedPerMonth
      )
      setProposalNextServiceToday(
        responseReportDashboard.data.proposalNextServiceToday
      )
      setProposalNextServiceWeek(
        responseReportDashboard.data.proposalNextServiceWeek
      )
      setContractsAwaitingSign(
        responseReportDashboard.data.partnersAwaitingSign
      )
      setPartnersAwaitingSign(
        responseReportDashboard.data.partnersAwaitingReleased
      )
      setPartnersActive(responseReportDashboard.data.partnersActive)
    } catch (error) {
    } finally {
      setIsLoadingCards(false)
    }
  }, [])

  useEffect(() => {
    loadDashboardCards()
  }, [loadDashboardCards])

  useEffect(() => {
    loadWarning()
  }, [loadWarning])

  useEffect(() => {
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  const handleClickOnClose = async () => {
    try {
      await api.post('/warningPartners/read', {
        warning_id: warning?.id
      })
    } catch (error) {
      console.log(error)
    } finally {
      setModalWarning(false)
    }
  }

  if (isLoadingCards || isLoadingWarning) {
    return <Loading isActive />
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Tela Inícial</PageTitle>

      {user.role_id === 1 && (
        <div className="row gy-5 g-xl-8">
          <div className="col-md-4">
            <CardSimple
              primaryColor="primary"
              text="Contratos aguardando assinatura"
              number={contractsAwaitingSign.toString()}
              footer={{
                link: `/commercial/partners?status=${PARTNER_STATUS_ENUM.AWAITING_SIGNED}&approved=0`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faFile
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="warning"
              text="Parceiros aguardando liberação"
              number={partnersAwaitingReleased.toString()}
              footer={{
                link: `/commercial/partners?status=${PARTNER_STATUS_ENUM.AWAITING_APPROVED}&approved=0`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="primary"
              text="Parceiros ativos"
              number={partnersActive.toString()}
              footer={{
                link: `/commercial/partners?status=${PARTNER_STATUS_ENUM.APPROVED}&approved=1`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="primary"
              text="Contratações formalizadas"
              number={hiringFormalizedPerMonth.toString()}
              footer={{
                link: `/commercial/hirings?status=${HIRING_STATUS_ENUM_NUMBER.HIRING_FORMALIZED}&start_date=${startMonthDate}&end_date=${endMonthDate}`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="warning"
              text="Contratações em andamento"
              number={hiringInProgressPerMonth.toString()}
              footer={{
                link: `/commercial/hirings?status=${HIRING_STATUS_ENUM_NUMBER.HIRING_IN_PROGRESS}&start_date=${startMonthDate}&end_date=${endMonthDate}`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
        </div>
      )}
      {user.role_id === 2 && (
        <div className="row gy-5 g-xl-8">
          <div className="col-md-4">
            <CardSimple
              primaryColor="warning"
              text="Atendimentos do dia"
              number={proposalNextServiceToday.toString()}
              footer={{
                link: `/commercial/proposals?start_date=${startTodayDate}&end_date=${endTodayDate}`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="primary"
              text="Atendimento da semana"
              number={proposalNextServiceWeek.toString()}
              footer={{
                link: `/commercial/proposals?start_date=${startWeekDate}&end_date=${endWeekDate}`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="primary"
              text="Contratações formalizadas"
              number={hiringFormalizedPerMonth.toString()}
              footer={{
                link: `/commercial/hirings?status=${HIRING_STATUS_ENUM_NUMBER.HIRING_FORMALIZED}&start_date=${startMonthDate}&end_date=${endMonthDate}`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
          <div className="col-md-4">
            <CardSimple
              primaryColor="warning"
              text="Contratações em andamento"
              number={hiringInProgressPerMonth.toString()}
              footer={{
                link: `/commercial/hirings?status=${HIRING_STATUS_ENUM_NUMBER.HIRING_IN_PROGRESS}&start_date=${startMonthDate}&end_date=${endMonthDate}`,
                text: 'Mais Detalhes',
                iconSettings: {
                  icon: faArrowCircleRight,
                  style: {
                    size: 'lg'
                  }
                }
              }}
              iconSettings={{
                icon: faUsers
              }}
            />
          </div>
        </div>
      )}

      {modalWarning && warning && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalWarning}
          pageTitle={warning?.title}
          styles={{ padding: '30px 350px' }}
          Children={<ContainerWarning {...warning} />}
        />
      )}
    </>
  )
}

export default Dashboard
