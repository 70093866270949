export const HIRING_STATUS_ENUM: Record<string, string> = {
  new: 'Novo',
  finished: 'Finalizado'
}

export const HIRING_STATUS_ENUM_NUMBER = {
  HIRING_CREATE: 1,
  HIRING_DISPATCH: 2,
  HIRING_SENT_ANALYSIS: 3,
  HIRING_AWAITING_FORMALIZATION: 4,
  HIRING_FORMALIZED: 5,
  HIRING_IN_PROGRESS: 6
}

export const HIRING_STATUS_OPTIONS = Object.entries(HIRING_STATUS_ENUM).map(
  ([key, value]) => ({
    name: value,
    value: key
  })
)
