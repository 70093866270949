import React, { useCallback, useEffect, useState } from 'react'
import { Title } from 'components/Title'
import { SearchComponentProduct } from 'pages/Commercial/Proposals/components/SearchComponentProduct'
import { Input } from 'components/Form'
import { Date as DatePicker } from '../../components/Form/date'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { Loading } from 'components/Loading'
import { useAuth } from 'hooks/auth'

export function AddHiringForm({
  initialValues,
  isEditMode
}: {
  initialValues: any
  isEditMode?: boolean
}) {
  const { user } = useAuth()
  const { addToast } = useToast()
  const [isLoadingBank, setIsLoadingBank] = useState(false)
  const [isLoadingPartner, setIsLoadingPartner] = useState(false)
  // const [isLoadingProposal, setIsLoadingProposal] = useState(false)
  const [partnersSearch, setPartnersSearch] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [selectedPartner, setSelectedPartner] = useState([
    { id: 0, selected: false }
  ])
  const [partners, setPartners] = useState<any[]>([])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])

  const [banksSearch, setBanksSearch] = useState<any[]>([
    {},
    { id: 0, name: '' }
  ])
  const [selectedBank, setSelectedBank] = useState([
    {},
    { id: 0, selected: false }
  ])
  const [banks, setBanks] = useState<any[]>([])
  const [hasFocusBank, setHasFocusBank] = useState([
    {},
    { id: 0, hasFocus: false }
  ])
  // const [proposalsSearch, setProposalsSearch] = useState<any[]>([
  //   { id: 0, name: '' }
  // ])
  // const [selectedProposal, setSelectedProposal] = useState([
  //   { id: 0, selected: false }
  // ])
  // const [proposals, setProposals] = useState<any[]>([])
  // const [hasFocusProposal, setHasFocusProposal] = useState([
  //   { id: 0, hasFocus: false }
  // ])

  const [hiringDate, setHiringDate] = useState<Date>()

  const loadBanks = useCallback(async () => {
    setIsLoadingBank(true)
    try {
      const banksResponse = await api.get('/commercial/banks/options')
      setBanks(
        banksResponse.data.map(
          (bank: { id: number; name: string }) =>
            bank && { name: bank.name, value: bank.id, id: bank.id }
        )
      )
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    } finally {
      setIsLoadingBank(false)
    }
  }, [addToast])

  const loadPartners = useCallback(async () => {
    setIsLoadingPartner(true)
    try {
      const partnersResponse = await api.get('/commercial/partners/options')
      setPartners(
        partnersResponse.data.map(
          (partner: { id: number; name: string }) =>
            partner && { name: partner.name, value: partner.id, id: partner.id }
        )
      )
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    } finally {
      setIsLoadingPartner(false)
    }
  }, [addToast])

  // const loadProposals = useCallback(async () => {
  //   setIsLoadingProposal(true)
  //   try {
  //     const proposalsResponse = await api.get('/commercial/proposals/options')
  //     setProposals(
  //       proposalsResponse.data.map(
  //         (proposal: { id: number; name: string }) =>
  //           proposal && { name: proposal.name, value: proposal.id }
  //       )
  //     )
  //   } catch (err) {
  //     addToast({
  //       type: 'error',
  //       title: 'Error ao carregar o parceiro',
  //       description:
  //         'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
  //     })
  //   } finally {
  //     setIsLoadingProposal(false)
  //   }
  // }, [addToast])

  useEffect(() => {
    loadBanks()
    if (user.role_id === 1) {
      loadPartners()
    }
    // loadProposals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initialValues) {
      setPartnersSearch([
        {
          id: initialValues.partner_id,
          name: initialValues.partner?.name
        }
      ])
      setBanksSearch([
        {},
        {
          id: initialValues.bank_id,
          name: initialValues.bank?.name
        }
      ])
    }
  }, [initialValues])

  if (isLoadingBank || isLoadingPartner) {
    return <Loading isActive />
  }

  return (
    <div className="row mb-default">
      <Title title="Informações" className="mt-0" />
      {isEditMode && (
        <Input
          className="col-6"
          name="number_hiring_bank"
          label="Nº da proposta"
          rules={{ required: false }}
        />
      )}
      {user?.role_id === 1 && (
        <div className="col-6">
          {SearchComponentProduct({
            index: 0,
            data: partnersSearch,
            setData: setPartnersSearch,
            searchData: partners,
            selected: selectedPartner,
            setSelected: setSelectedPartner,
            label: 'Parceiro',
            hasFocus,
            setHasFocus,
            name: 'partner_id'
          })}
        </div>
      )}

      {/* <div className="col-6">
        {SearchComponentProduct({
          index: 0,
          data: proposalsSearch,
          setData: setProposalsSearch,
          searchData: proposals,
          selected: selectedProposal,
          setSelected: setSelectedProposal,
          label: 'Proposta',
          hasFocus: hasFocusProposal,
          setHasFocus: setHasFocusProposal,
          name: 'proposal_id'
        })}
      </div> */}

      <Input
        className="col-6"
        name="client_name"
        label="Nome do cliente"
        rules={{ required: true }}
      />
      <Input
        className="col-3"
        name="client_cpf"
        label="CPF do cliente"
        rules={{ required: true }}
        mask={{
          mask: '999.999.999-99',
          type: 'cpf'
        }}
        controlled
      />
      <div className="col-3">
        {SearchComponentProduct({
          index: 1,
          data: banksSearch,
          setData: setBanksSearch,
          searchData: banks,
          selected: selectedBank,
          setSelected: setSelectedBank,
          label: 'Banco',
          hasFocus: hasFocusBank,
          setHasFocus: setHasFocusBank,
          name: 'bank_id'
        })}
      </div>
      <Input
        className="col-3"
        name="property_value"
        label="Valor do imóvel"
        rules={{ required: true }}
        price
      />
      <Input
        className="col-3"
        name="financed_value"
        label="Valor Financiado"
        rules={{ required: true }}
        price
      />
      <Input
        className="col-3"
        name="installment_value"
        label="Valor Parcela"
        rules={{ required: true }}
        price
      />
      <Input
        className="col-3"
        name="term"
        label="Prazo"
        rules={{ required: false }}
        mask={{
          mask: '999'
        }}
        controlled
      />
      <Input
        className="col-3"
        name="interest_amount"
        label="Percentual de Juros"
        rules={{ required: true }}
        price
      />
      <div className="col-md-3">
        <DatePicker
          name="date"
          label="Data"
          selected={hiringDate}
          onChange={e => setHiringDate(e)}
          rules={{ required: true }}
          controlled
        />
      </div>
    </div>
  )
}
