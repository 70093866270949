import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type PartnerData = {
  id?: number
  user?: User
  create_user?: number
  different_ownership?: number
  cpf_cnpj_ownership?: string
  type?: string
  status_id?: string
  files?: any[]
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: PartnerData
  typeForm: 'create' | 'update'
}
export const FormFile = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<PartnerData>()
  const [statusHiring, setStatusHiring] = useState([])
  const [files, setFiles] = useState([])
  const [removedFiles, setRemovedFiles] = useState([])

  const loadStatusHiring = useCallback(async () => {
    activeLoading()
    try {
      const responseStatusHiring = await api.get('/commercial/statusHirings')
      setStatusHiring(
        responseStatusHiring.data.map(
          (status: { id: number; name: string }) =>
            status && {
              name: status.name,
              value: Number(status.id)
            }
        )
      )
    } catch (error) {}
    disableLoading()
  }, [activeLoading, disableLoading])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues(
        typeForm === 'update'
          ? { ...initialValues }
          : {
              status_id: initialValues.status_id
            }
      )
      setFiles(initialValues.files)
    }
  }, [initialValues, typeForm])

  useEffect(() => {
    loadStatusHiring()
  }, [loadStatusHiring])

  const onSubmitForm = useCallback(
    async (data: any) => {
      const id = initialValues?.id
      data.hiring_id = isOpenInModal?.idParent
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]: any) => {
        if (key === 'file') {
          Object.entries(value).forEach(([, subValue]: any) => {
            if (subValue[0]) {
              formData.append(key, subValue[0])
            }
          })
          return
        }
        // if (typeForm === 'update' && key === 'file') {
        //   formData.delete(key)
        //   return
        // }
        formData.append(key, value)
      })
      formData.append('removedFiles', JSON.stringify(removedFiles))

      try {
        if (typeForm === 'create') {
          if (isOpenInModal) {
            const { handleOnClose } = isOpenInModal
            activeLoading()
            try {
              await api.post('/commercial/hiringsStatus/create', formData)
              handleOnClose()
              disableLoading()
              updateDataTable()
              addToast({
                type: 'success',
                title: 'Registro criado',
                description: 'Registro criado com sucesso'
              })
            } catch (error) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
              })
              handleOnClose()
              disableLoading()
              updateDataTable()
            }
          } else {
            try {
              activeLoading()
              await api.post(apiCreate(), formData)
              disableLoading()
              updateDataTable()
              addToast({
                type: 'success',
                title: 'Registro criado',
                description: 'Registro criado com sucesso'
              })
              history.push(nameActions.read.to)
            } catch (error) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
              })
              disableLoading()
              updateDataTable()
            }
          }
        } else {
          if (isOpenInModal) {
            const { handleOnClose } = isOpenInModal

            try {
              activeLoading()
              await api.put(`/commercial/hiringsStatus/update/${id}`, formData)
              updateDataTable()
              disableLoading()
              handleOnClose()
              addToast({
                type: 'success',
                title: 'Registro atualizado',
                description: 'Registro alterado com sucesso'
              })
            } catch (error) {
              disableLoading()
              handleOnClose()
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
              })
            }
          } else {
            const dataUpdate = {
              ...data,
              user: {
                ...defaultValues?.user,
                ...data.user
              }
            }

            try {
              activeLoading()
              await api.put(apiUpdate(String(id)), dataUpdate)
              updateDataTable()
              disableLoading()
              history.push(nameActions.read.to)
              addToast({
                type: 'success',
                title: 'Registro atualizado',
                description: 'Registro alterado com sucesso'
              })
            } catch (error) {
              history.push(nameActions.read.to)
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
              })
            }
          }
        }
        disableLoading()
      } catch (err) {
        if (typeForm === 'create') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          if (isOpenInModal) isOpenInModal.handleOnClose()
        }
      }
    },
    [
      activeLoading,
      addToast,
      defaultValues?.user,
      disableLoading,
      history,
      initialValues?.id,
      isOpenInModal,
      removedFiles,
      typeForm,
      updateDataTable
    ]
  )

  const handleRemoveFile = (file: string) => {
    setRemovedFiles(oldRemovedFile => {
      return [...oldRemovedFile, file]
    })
  }

  const handleNameOfFile = (
    item: { filename: string },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: { maxLength: number }
  ) => {
    let filename = item?.filename
    const indexTrace = filename.indexOf('-') + 1
    filename = filename.slice(indexTrace, filename.length)
    return filename
  }

  const handleDownload = useCallback(
    (item: { filename: string; url: string }) => {
      const filename = handleNameOfFile(item)
      fetch(item?.url).then(response => {
        response.blob().then((blobObject: any) => {
          const url = window.URL.createObjectURL(blobObject)
          const a = document.createElement('a')
          a.download = filename
          a.href = url
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
      })
    },
    []
  )

  return (
    <FormContainer>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className="form">
          <div className="row mb-default">
            <Select
              className="col-6"
              name="status_id"
              label="Novo Status"
              rules={{ required: true }}
              defaultValue=""
              blank
              options={statusHiring.map(status => {
                return status.value !== Number(defaultValues?.status_id)
                  ? { ...status }
                  : { ...status, disabled: true }
              })}
            />
          </div>

          <div className="row mb-default">
            <Textarea
              className="col-12"
              label="Observações"
              name="observations"
              defaultValue=""
              style={{ minHeight: 150 }}
            />
          </div>

          <div className="row mb-default">
            <div className="col-6">
              <Input
                className="col-12"
                type="file"
                label="Anexo 1"
                name="file.1"
              />

              {files?.[0] && (
                <>
                  <p
                    className="text-primary cursor-pointer align-itens-center mt-5"
                    onClick={() => handleDownload(files?.[0])}
                  >
                    <span className="fa fa-download mx-2"></span>
                    {handleNameOfFile(files?.[0], { maxLength: 70 })}
                  </p>
                  <div
                    className="form-check form-check-sm form-check-custom form-check-solid d-flex align-itens-center mt-5"
                    onClick={() => handleRemoveFile(files?.[0]?.filename)}
                  >
                    <input type="checkbox" className="form-check-input me-2" />
                    <p className="cursor-pointer align-itens-center">
                      Remover Anexo 1
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-6">
              <Input
                className="col-12"
                type="file"
                label="Anexo 2"
                name="file.2"
              />

              {files?.[1] && (
                <>
                  <p
                    className="text-primary cursor-pointer align-itens-center mt-5"
                    onClick={() => handleDownload(files?.[1])}
                  >
                    <span className="fa fa-download mx-2"></span>
                    {handleNameOfFile(files?.[1], { maxLength: 70 })}
                  </p>
                  <div
                    className="form-check form-check-sm form-check-custom form-check-solid d-flex align-itens-center mt-2"
                    onClick={() => handleRemoveFile(files?.[1]?.filename)}
                  >
                    <input type="checkbox" className="form-check-input me-5" />
                    <p className="cursor-pointer align-itens-center">
                      Remover Anexo 2
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mb-default">
            <div className="col-6">
              <Input
                className="col-12"
                type="file"
                label="Anexo 3"
                name="file.3"
              />

              {files?.[2] && (
                <>
                  <p
                    onClick={() => handleDownload(files?.[2])}
                    className="text-primary cursor-pointer align-itens-center mt-2"
                  >
                    <span className="fa fa-download mx-2"></span>
                    {handleNameOfFile(files?.[2], { maxLength: 70 })}
                  </p>
                  <div
                    className="form-check form-check-sm form-check-custom form-check-solid d-flex align-itens-center mt-2"
                    onClick={() => handleRemoveFile(files?.[2]?.filename)}
                  >
                    <input type="checkbox" className="form-check-input me-2" />
                    <p className="cursor-pointer align-itens-center">
                      Remover Anexo 3
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-6">
              <Input
                className="col-12"
                type="file"
                label="Anexo 4"
                name="file.4"
              />

              {files?.[3] && (
                <>
                  <p
                    onClick={() => handleDownload(files?.[3])}
                    className="text-primary cursor-pointer align-itens-center mt-2"
                  >
                    <span className="fa fa-download mx-2"></span>
                    {handleNameOfFile(files?.[3], { maxLength: 70 })}
                  </p>
                  <div
                    className="form-check form-check-sm form-check-custom form-check-solid d-flex align-itens-center mt-2"
                    onClick={() => handleRemoveFile(files?.[3]?.filename)}
                  >
                    <input type="checkbox" className="form-check-input me-2" />
                    <p className="cursor-pointer align-itens-center">
                      Remover Anexo 4
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mb-default">
            <div className="col-6">
              <Input
                className="col-12"
                type="file"
                label="Anexo 5"
                name="file.5"
              />

              {files?.[4] && (
                <>
                  <p
                    onClick={() => handleDownload(files?.[4])}
                    className="text-primary cursor-pointer align-itens-center mt-2"
                  >
                    <span className="fa fa-download mx-2"></span>
                    {handleNameOfFile(files?.[4], { maxLength: 70 })}
                  </p>
                  <div
                    className="form-check form-check-sm form-check-custom form-check-solid d-flex align-itens-center mt-2"
                    onClick={() => handleRemoveFile(files?.[4]?.filename)}
                  >
                    <input type="checkbox" className="form-check-input me-2" />
                    <p className="cursor-pointer align-itens-center">
                      Remover Anexo 5
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-6">
              <Input
                className="col-12"
                type="file"
                label="Anexo 6"
                name="file.6"
              />

              {files?.[5] && (
                <>
                  <p
                    onClick={() => handleDownload(files?.[5])}
                    className="text-primary cursor-pointer align-itens-center mt-2"
                  >
                    <span className="fa fa-download mx-2"></span>
                    {handleNameOfFile(files?.[5], { maxLength: 70 })}
                  </p>
                  <div
                    className="form-check form-check-sm form-check-custom form-check-solid d-flex align-itens-center mt-2"
                    onClick={() => handleRemoveFile(files?.[5]?.filename)}
                  >
                    <input type="checkbox" className="form-check-input me-2" />
                    <p className="cursor-pointer align-itens-center">
                      Remover Anexo 6
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end px-0 mt-5">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </FormContainer>
  )
}
