export const OPTIONS_TYPE_PERSON = [
  {
    name: 'Person'
  }
]
export const SPACING_ROW_FORM = '2'

export const dateInputFormat = 'DD/MM/YYYY'

export const DATE_FORMATS = {
  ISO: 'YYYY-MM-DD',
  LOCALE: 'DD/MM/YYYY',
  LOCALE_WITH_TIME: 'DD/MM/YYYY HH:mm:ss'
}

export const OPTIONS_MARITAL_STATUS = [
  {
    name: 'Solteiro (a)',
    value: 'Solteiro'
  },
  {
    name: 'Casado (a)',
    value: 'Casado'
  },
  {
    name: 'Divorciado (a)',
    value: 'Divorciado'
  },
  {
    name: 'Outros',
    value: 'Outros'
  }
]

export const OPTIONS_YES_OR_NO = [
  {
    name: 'Sim',
    value: 1
  },
  {
    name: 'Não',
    value: 0
  }
]

export const OPTIONS_TYPE_ACCOUNT_BANK = [
  {
    name: 'Conta Corrente',
    value: 'Conta Corrente'
  },
  {
    name: 'Conta Poupança',
    value: 'Conta Poupança'
  }
]

export const OPTIONS_IMMOBILE_TYPES = [
  {
    name: 'Casa',
    value: 'Casa'
  },
  {
    name: 'Apartamento',
    value: 'Apartamento'
  },
  {
    name: 'Comercial',
    value: 'Comercial'
  },
  {
    name: 'Lote em condomínio',
    value: 'Lote em condomínio'
  }
]

export const OPTIONS_CREDIT_LINE = [
  {
    name: 'SFH',
    value: 'SFH'
  },
  {
    name: 'Portabilidade',
    value: 'Portabilidade'
  },
  {
    name: 'Empréstimo MCMV',
    value: 'Empréstimo MCMV'
  }
]

export const OPTIONS_INDICATION_TYPE = [
  {
    name: 'Agência',
    value: 'Agência'
  },
  {
    name: 'Parceiro',
    value: 'Parceiro'
  },
  {
    name: 'Equipe Própria',
    value: 'Equipe Própria'
  }
]

export const OPTIONS_TYPE_DATE_PROPOSAL_FILTER = [
  {
    name: 'Data de Atendimento',
    value: 'next_service'
  },
  {
    name: 'Data de Cadastro',
    value: 'created_at'
  }
]

export const RANDOM_KEY = 'Chave Aleatória'
export const CELL_PHONE = 'Celular'
export const EMAIL = 'Email'
export const CPF = 'Cpf'

export const OPTIONS_TYPE_PIX_KEY = [
  { name: CPF, value: CPF },
  { name: EMAIL, value: EMAIL },
  { name: CELL_PHONE, value: CELL_PHONE },
  { name: RANDOM_KEY, value: RANDOM_KEY }
]

export const OPTIONS_IMMOBILE_SITUATION = [
  {
    name: 'Novo',
    value: 'Novo'
  },
  {
    name: 'Usado',
    value: 'Usado'
  }
]

export const OPTIONS_TYPE_PARTNER = [
  {
    name: 'Substabelecido',
    value: 'Substabelecido'
  },
  {
    name: 'Colaborador',
    value: 'Colaborador'
  },
  {
    name: 'Indicador',
    value: 'Indicador'
  }
]

export const OPTIONS_TYPE_PARTNER_MULTISELECT = [
  {
    name: 'Substabelecido',
    value: 'Deputy'
  },
  {
    name: 'Colaborador',
    value: 'Collaborator'
  },
  {
    name: 'Indicador',
    value: 'Indicator'
  }
]

export const OPTIONS_STATUS_PARTNER = [
  {
    name: 'Aprovado',
    value: 1
  },
  {
    name: 'Reprovado',
    value: 0
  }
]

export const LOCAL_STORAGE_KEYS = {
  SELECTED_MENU: '@NeoHome:SelectedMenu',
  TOKEN: '@NeoHome:token',
  USER: '@NeoHome:user',
  MENUS: '@NeoHome:menus',
  STORAGE_WARNING_SHOW: '@NeoHome:STORAGE_WARNING_SHOW'
}

export const HANDLE_ERROS_MESSAGE = [
  {
    error: 'Cpf already used',
    message: 'CPF já cadastrado'
  },
  {
    error: 'Not found.',
    message: 'CPF informado não foi encontrado na base de dados.'
  }
]

export enum APIS_NAMES {
  SIM = 'Sim',
  LEMIT = 'Lemit',
  CREDCESTA = 'Credcesta'
}

export enum INPUT_MASK {
  PHONE = '(99) 9999-9999',
  CELL_PHONE = '(99) 99999-9999'
}
export const TYPE_PERSON: {
  [key: string]: {
    NAME: string
    ABBREVIATION: string
    DOCUMENT: string
    MASK: string
  }
} = {
  PF: {
    NAME: 'Pessoa Física',
    ABBREVIATION: 'PF',
    DOCUMENT: 'cpf',
    MASK: '999.999.999-99'
  },
  PJ: {
    NAME: 'Pessoa Jurídica',
    ABBREVIATION: 'PJ',
    DOCUMENT: 'cnpj',
    MASK: '99.999.999/9999-99'
  }
}
export const states = [
  {
    value: 'RO',
    name: 'Rondônia'
  },
  {
    value: 'AC',
    name: 'Acre'
  },
  {
    value: 'AM',
    name: 'Amazonas'
  },
  {
    value: 'RR',
    name: 'Roraima'
  },
  {
    value: 'PA',
    name: 'Pará'
  },
  {
    value: 'AP',
    name: 'Amapá'
  },
  {
    value: 'TO',
    name: 'Tocantins'
  },
  {
    value: 'MA',
    name: 'Maranhão'
  },
  {
    value: 'PI',
    name: 'Piauí'
  },
  {
    value: 'CE',
    name: 'Ceará'
  },
  {
    value: 'RN',
    name: 'Rio Grande do Norte'
  },
  {
    value: 'PB',
    name: 'Paraíba'
  },
  {
    value: 'PE',
    name: 'Pernambuco'
  },
  {
    value: 'AL',
    name: 'Alagoas'
  },
  {
    value: 'SE',
    name: 'Sergipe'
  },
  {
    value: 'BA',
    name: 'Bahia'
  },
  {
    value: 'MG',
    name: 'Minas Gerais'
  },
  {
    value: 'ES',
    name: 'Espírito Santo'
  },
  {
    value: 'RJ',
    name: 'Rio de Janeiro'
  },
  {
    value: 'SP',
    name: 'São Paulo'
  },
  {
    value: 'PR',
    name: 'Paraná'
  },
  {
    value: 'SC',
    name: 'Santa Catarina'
  },
  {
    value: 'RS',
    name: 'Rio Grande do Sul'
  },
  {
    value: 'MS',
    name: 'Mato Grosso do Sul'
  },
  {
    value: 'MT',
    name: 'Mato Grosso'
  },
  {
    value: 'GO',
    name: 'Goiás'
  },
  {
    value: 'DF',
    name: 'Distrito Federal'
  }
].sort((currentState, nextState) =>
  currentState.name.localeCompare(nextState.name)
)
