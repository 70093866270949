export const PROPOSAL_PRODUCT_TYPE_ENUM = {
  imobiliary_credit: 'Crédito imobiliário',
  credit_property_guarantee: 'Crédito com garantia de imóvel'
}

export const PROPOSAL_PRODUCT_TYPE_OPTIONS = Object.entries(
  PROPOSAL_PRODUCT_TYPE_ENUM
).map(([key, value]) => ({
  name: value,
  value: key
}))
