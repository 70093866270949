import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { AddHiringForm } from 'components/AddHiringForm'
import { useAuth } from 'hooks/auth'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type HiringData = {
  id?: number
  name?: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: HiringData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormHiring = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { user } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<HiringData>()
  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const onSubmitForm = async (data: any) => {
    const id = initialValues?.idUpdate
    const formData = {
      proposal_id: data.proposal_id,
      client_name: data.client_name,
      client_cpf: data.client_cpf,
      property_value: data.property_value,
      financed_value: data.financed_value,
      installment_value: data.installment_value,
      number_hiring_bank: data.number_hiring_bank,
      term: data.term,
      date: data.date,
      interest_amount: data.interest_amount,
      partner_id: user.role_id === 1 ? Number(data.data[0].id) : user.uid,
      bank_id: Number(data.data[1].id)
    }

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          try {
            await api.post(apiCreate(), formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      {AddHiringForm({ initialValues, isEditMode: typeForm === 'update' })}
      <div className="card-footer d-flex justify-content-end px-0 mt-5">
        <button type="submit" className="btn btn-primary">
          Salvar
        </button>
      </div>
    </Form>
  )
}
