import React, { useEffect, useRef, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select, Textarea } from '../../../../../components/Form'

import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiUpdate } from '../../domain/api'

import { OPTIONS_STATUS_PARTNER } from 'common/constants'
import { FormContainer } from './styles'
import { Title } from 'components/Title'

import { Alert } from 'components/Alert'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type PartnerData = {
  id?: number
  name?: string
  create_user?: number
  approved?: number | string
  different_ownership?: number
  cpf_cnpj_ownership?: string
  type?: string
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: PartnerData
}
export const FormChangeStatus = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<PartnerData>()
  const [hasObservations, setHasObservations] = useState(false)
  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        approved: ''
      })
      setHasObservations(!Number(initialValues?.approved))
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: PartnerData) => {
    const id = initialValues?.id
    const { handleOnClose } = isOpenInModal
    const dataUpdate = {
      ...initialValues,
      ...data
    }

    try {
      activeLoading()
      await api.put(apiUpdate(String(id)), dataUpdate)
      updateDataTable()
      disableLoading()
      handleOnClose()
      addToast({
        type: 'success',
        title: 'Parceiro atualizado',
        description: 'Parceiro alterado com sucesso'
      })
      handleOnClose()
    } catch (error) {
      disableLoading()
      handleOnClose()
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o Parceiro',
        description:
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
      })
    }
  }
  return (
    <FormContainer>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className="form">
          <div className="row mb-default">
            <Select
              className="col-3"
              name="approved"
              label="Status"
              options={OPTIONS_STATUS_PARTNER}
              onChange={event => {
                if (Number(event.target.value) === 0) {
                  setHasObservations(true)
                  return
                }
                setHasObservations(false)
              }}
              controlled
              blank
              defaultValue=""
              rules={{ required: true }}
            />
          </div>

          {hasObservations && (
            <div className="row mb-default">
              <Title title="Motivo" />
              <Textarea
                className="col-12"
                label=""
                name="approved_reason"
                rules={{ required: true }}
                style={{ minHeight: 150 }}
              />
            </div>
          )}
          <div className="card-footer d-flex justify-content-end px-0 mt-5">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </FormContainer>
  )
}
