import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { FilterContainer } from './styles'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { Date as DatePicker } from '../../../../components/Form/date'
import { Select } from 'components/Form'
import { SearchComponentProduct } from 'pages/Commercial/Proposals/components/SearchComponentProduct'
import { useAuth } from 'hooks/auth'
import { HiringDataTable } from '../components/HiringDataTable'
import { Loading } from 'components/Loading'
import { StringParam, useQueryParam } from 'use-query-params'
import moment from 'moment'
import { HIRING_STATUS_ENUM_NUMBER } from 'common/constants/hiring-status'
import { useHistory } from 'react-router-dom'

const List: React.FC = () => {
  const { addToast } = useToast()
  const { user } = useAuth()
  const history = useHistory()
  const [statusQuery] = useQueryParam('status', StringParam)
  const [startDateQuery] = useQueryParam('start_date', StringParam)
  const [endDateQuery] = useQueryParam('end_date', StringParam)
  const [isLoadingBank, setIsLoadingBank] = useState(false)
  const [isLoadingPartner, setIsLoadingPartner] = useState(false)
  const [isLoadingStatusHiring, setIsLoadingStatusHiring] = useState(false)
  const [partners, setPartners] = useState<any[]>([])
  const [statusHiring, setStatusHiring] = useState<any[]>([])
  const [parameters, setParameters] = useState<any>([])
  const [statusValue, setStatusValue] = useState<string>('')
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [partnersSearch, setPartnersSearch] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])
  const [selectedPartner, setSelectedPartner] = useState([
    { id: 0, selected: false }
  ])
  const [banksSearch, setBanksSearch] = useState<any[]>([
    {},
    { id: 0, name: '' }
  ])
  const [selectedBank, setSelectedBank] = useState([
    {},
    { id: 0, selected: false }
  ])
  const [banks, setBanks] = useState<any[]>([])
  const [hasFocusBank, setHasFocusBank] = useState([
    {},
    { id: 0, hasFocus: false }
  ])

  const loadPartners = useCallback(async () => {
    setIsLoadingPartner(true)
    try {
      const { data } = await api.get('/commercial/partners/options')
      setPartners(
        data.map(
          (partner: { id: number; name: string }) =>
            partner && { name: partner.name, value: partner.id, id: partner.id }
        )
      )
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    } finally {
      setIsLoadingPartner(false)
    }
  }, [addToast])

  const loadStatusHiring = useCallback(async () => {
    setIsLoadingStatusHiring(true)
    try {
      const { data } = await api.get('/commercial/statusHirings/listOptions')
      setStatusHiring(
        [
          ...data,
          {
            name: 'CONTRATAÇÃO EM PROGRESSO',
            id: HIRING_STATUS_ENUM_NUMBER.HIRING_IN_PROGRESS
          }
        ].map(
          (statusHiring: { id: number; name: string }) =>
            statusHiring && { name: statusHiring.name, value: statusHiring.id }
        )
      )
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error ao carregar o status',
        description:
          'Houve um error ao carregar o status, tente novamente mais tarde!'
      })
    } finally {
      setIsLoadingStatusHiring(false)
    }
  }, [addToast])

  const loadBanks = useCallback(async () => {
    setIsLoadingBank(true)
    try {
      const banksResponse = await api.get('/commercial/banks/options')
      setBanks(
        banksResponse.data.map(
          (bank: { id: number; name: string }) =>
            bank && { name: bank.name, value: bank.id, id: bank.id }
        )
      )
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    } finally {
      setIsLoadingBank(false)
    }
  }, [addToast])
  useEffect(() => {
    if (user?.role_id === 1) {
      loadPartners()
    }
    loadBanks()
    loadStatusHiring()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGenerateExport = useCallback(async () => {
    try {
      const responseHirings = await api.post(
        '/commercial/hirings/generateExport',
        {
          status: statusValue || undefined,
          start_date: startDate,
          end_date: endDate,
          partner_id:
            user.role_id === 1 ? selectedPartner[0]?.id || undefined : user.uid,
          bank_id: selectedBank[1]?.id || undefined
        }
      )
      const blob = new Blob(['\ufeff' + responseHirings.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = 'relatório-contratacoes.csv'
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error ao baixar o relatório',
        description:
          'Houve um error ao baixar o relatório de contratações, tente novamente mais tarde!'
      })
      console.log(error)
    }
  }, [addToast, endDate, selectedBank, selectedPartner, startDate, statusValue])

  useEffect(() => {
    setParameters([
      {
        status: statusQuery,
        startDate: startDateQuery,
        endDate: endDateQuery
      }
    ])
    setStatusValue(statusQuery)
    if (startDateQuery) {
      setStartDate(moment(startDateQuery).toDate())
    }
    if (endDateQuery) {
      setEndDate(moment(endDateQuery).toDate())
    }
  }, [endDateQuery, startDateQuery, statusQuery])

  if (isLoadingPartner || isLoadingBank || isLoadingStatusHiring) {
    return <Loading isActive />
  }
  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <FilterContainer>
          <div className="row">
            {user?.role_id === 1 && (
              <div className="col-md-3">
                {SearchComponentProduct({
                  index: 0,
                  data: partnersSearch,
                  setData: setPartnersSearch,
                  searchData: partners,
                  selected: selectedPartner,
                  setSelected: setSelectedPartner,
                  label: 'Parceiro',
                  hasFocus,
                  setHasFocus,
                  name: 'partner_id'
                })}
              </div>
            )}
            <div className="col-3">
              {SearchComponentProduct({
                index: 1,
                data: banksSearch,
                setData: setBanksSearch,
                searchData: banks,
                selected: selectedBank,
                setSelected: setSelectedBank,
                label: 'Banco',
                hasFocus: hasFocusBank,
                setHasFocus: setHasFocusBank,
                name: 'bank_id'
              })}
            </div>
            <div className="col-md-3">
              <DatePicker
                name="start_date"
                label="Data de início"
                selected={startDate}
                onChange={e => {
                  if (endDate) {
                    if (e > endDate) {
                      setEndDate(null)
                    }
                  }
                  setStartDate(e)
                }}
                controlled
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                name="end_date"
                label="Data de término"
                selected={endDate}
                minDate={startDate}
                onChange={e => setEndDate(e)}
                controlled
              />
            </div>

            <div className="col-md-3">
              <Select
                name="status"
                label="Status"
                id="status"
                value={statusValue}
                onChange={({ target }) => setStatusValue(target.value)}
                options={statusHiring}
                blank
              />
            </div>
          </div>
          <footer>
            <button
              className="btn btn-light-primary"
              onClick={handleGenerateExport}
            >
              EXPORTAR
            </button>
            <button
              className="btn btn-light-primary"
              onClick={() => {
                setStatusValue('')
                setStartDate(null)
                setEndDate(null)
                setParameters([])
                setSelectedPartner([{ id: 0, selected: false }])
                setPartnersSearch([{ id: 0, name: '' }])
                setSelectedBank([{}, { id: 0, selected: false }])
                setBanksSearch([{}, { id: 0, name: '' }])
                history.replace('/commercial/hirings')
              }}
            >
              LIMPAR
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setParameters([
                  {
                    status: statusValue || undefined,
                    startDate,
                    endDate,
                    partner_id:
                      user.role_id === 1
                        ? selectedPartner[0]?.id || undefined
                        : user.uid,
                    bank_id: selectedBank[1]?.id || undefined
                  }
                ])
              }}
            >
              BUSCAR
            </button>
          </footer>
          <hr className="divider" />
        </FilterContainer>
        <HiringDataTable searchParams={parameters} />
      </Container>
    </>
  )
}
export default List
