import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import GlobalStyle from './styles/global'
import Routes from './routes'
import AppProvider from './hooks'
import { PrimeReactProvider } from 'primereact/api'
import './assets/sass/plugins.scss'
import './assets/sass/style.scss'
import './assets/sass/style.react.scss'
import { LayoutProvider, PageDataProvider } from './assets/layout/core'
import { MasterInit } from './assets/layout/MasterInit'
import 'primereact/resources/themes/lara-light-cyan/theme.css'

const App: React.FC = () => (
  <Router>
    <LayoutProvider>
      <AppProvider>
        <PageDataProvider>
          <QueryParamProvider ReactRouterRoute={Route}>
            <PrimeReactProvider>
              <MasterInit />
              <GlobalStyle />
              <Routes />
            </PrimeReactProvider>
          </QueryParamProvider>
        </PageDataProvider>
      </AppProvider>
    </LayoutProvider>
  </Router>
)

export default App
