import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'

import { Container, LinkContainer } from './style'
import { useUpdateDataTable } from '../../hooks/dataTable'
import { useLoading } from '../../hooks/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import convertDateToISO from '../../utlis/convertDateToISO'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Loading } from 'components/Loading'

interface Action {
  name: string
  icon?: IconProp
  spanIcon?: string
  htmlIcon?: JSX.Element
  element?: (item: any) => JSX.Element
  title: string
  link?: string
  onClick?: (params: any) => void
  linkTo?: (params: any) => string
}

interface Header {
  name: string
  field: string
  sortable: boolean
  custom?: boolean
  searchable?: boolean
  element?: (item: any) => JSX.Element
}

interface SearchParameters {
  [key: string]: string
}

interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  entity: string
  source: string
  headers?: Header[]
  customHeaders?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  onlyParent?: boolean
  onlyView?: boolean
  parentId?: string
  viewField?: { source: string; field: string }
  entityId?: string
  searchParameters?: SearchParameters[]
  format: {
    orderBy: string
  }
  orderByField?: string
  orderBySort?: string
  disabledAction?: {
    view?: boolean
    edit?: boolean
  }
}

function getWindowSize() {
  const { innerHeight } = window
  return innerHeight
}

const DataTable = ({
  onActions,
  entity,
  source,
  notHasChildren,
  viewField,
  onlyView,
  disabledAction,
  onlyParent,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  customHeaders,
  actions,
  format,
  orderByField,
  orderBySort,
  parentId,
  entityId,
  searchParameters
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [ItemsPerPage, setItemsPerPage] = useState(50)
  const [minPages, setMinPages] = useState(1)
  const [maxPages, setMaxPages] = useState(5)
  const [hasTopPagination, setHasTopPagination] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [changedParams, setParams] = useState<any>()
  const tableContainerRef = useRef(null)
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const { isUpdated } = useUpdateDataTable()

  const { activeLoading, disableLoading } = useLoading()

  const handlerOnClickButtonList = (currentValue: any) => {
    if (viewField) {
      return `/${viewField.source}/view/${currentValue[viewField.field]}?tab=${
        currentValue.id
      }`
    }

    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      return `/${source}/view/${currentValue.id}`
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    return `/${source}/update/${currentValue.id}`
  }

  const handlerOnClickButtonRemove = (currentValue: any) => {
    if (onActions?.onClickButtonRemove) {
      onActions.onClickButtonRemove(currentValue)
    }
  }

  const loadParams = useCallback(() => {
    const newParams = {
      entity,
      source,
      keyword: changedParams?.keyword || '',
      page: changedParams?.page || 1,
      perPage: ItemsPerPage,
      orderByField,
      orderBySort,
      searchParameters,
      onlyParent,
      orderBy: format.orderBy,
      parentId,
      entityId,
      othersSearch: changedParams?.othersSearch || []
    }

    if (!parentId) Object.assign(newParams, { parentId: '' })
    if (!entityId) Object.assign(newParams, { entityId: '' })
    return newParams
  }, [
    entity,
    source,
    changedParams?.keyword,
    changedParams?.page,
    changedParams?.othersSearch,
    ItemsPerPage,
    orderByField,
    orderBySort,
    searchParameters,
    onlyParent,
    format.orderBy,
    parentId,
    entityId
  ])

  const handleChangePage = (page: number) => {
    setParams((old: any) => ({
      ...old,

      page
    }))
  }
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const params = loadParams()
        const response = await api.get('dataTable', { params })
        setItems(response.data.items)
        setTotalItems(response.data.totalItens)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error(error)
      }
    })()
  }, [isUpdated, activeLoading, disableLoading, loadParams])
  const firstItem =
    totalItems === 0
      ? totalItems
      : ItemsPerPage * ((changedParams?.page || 1) - 1) + 1

  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      if (notHasChildren) {
        sum = items.reduce((sum, value) => {
          if (!value.parent_id) {
            return sum + 1
          }
          return sum
        }, 0)
      } else {
        sum = items.length
      }
    }
    return sum
  }

  const getSearched = async (value: string) => {
    const searchableHeaders = headers.filter(header => header.searchable)
    setParams((old: any) => ({
      ...old,
      page: 1,
      keyword: { [format.orderBy]: value },
      othersSearch: searchableHeaders.map(searchHeader => searchHeader.field)
    }))
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    if (value.length === 0) {
      setParams((old: any) => ({
        ...old,
        page: 1,
        keyword: '',
        othersSearch: ['']
      }))
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)

    timeOutId = setTimeout(() => getSearched(value), 1000)
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  useEffect(() => {
    if (tableContainerRef.current) {
      const height = tableContainerRef.current.clientHeight
      if (height > windowSize) {
        setHasTopPagination(true)
      } else {
        setHasTopPagination(false)
      }
    }
  }, [items, windowSize])
  return (
    <Container>
      <Loading isActive={isLoading} />
      {hasTopPagination && (
        <div className="d-flex flex-stack align-items-end mb-5">
          <div className="">
            <div className="dataTables_info" id="kt_datatable">
              Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
              {totalItems} registros
            </div>
          </div>
          <div className="">
            <div className="dataTables_paginate paging_bootstrap_number">
              <Pagination
                total={totalItems}
                itemsPerPage={ItemsPerPage}
                currentPage={changedParams?.page}
                onPageChange={handleChangePage}
                maxPages={maxPages}
                minPages={minPages}
                setMaxPages={setMaxPages}
                setMinPages={setMinPages}
              />
            </div>
          </div>
        </div>
      )}
      <div className="card-header p-0 align-items-center py-5 gap-2 gap-md-5">
        <div className="">
          <div id="kt_datatable" className="dataTables_length">
            <label>
              <select
                onChange={e => setItemsPerPage(Number(e.target.value))}
                className="form-select form-select-solid form-select-lg fw-bold"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              <p className="">resultados por página</p>
            </label>
          </div>
        </div>
        <div className="">
          <div className="dataTables_filter">
            <label className="fw-bolder fs-6 text-gray-800">
              Pesquisar
              <Search onSearch={value => onSearchItem(value)} />
            </label>
          </div>
        </div>
      </div>
      <div className="table-scrollable">
        <table
          id="container_table"
          className="table table-bordered border table-striped gs-3 table-hover"
          ref={tableContainerRef}
        >
          <TableHeader
            headers={headers}
            onSorting={(field, order, type) => {
              const itemSorted = items.sort((a, b) => {
                const fields = field.split('.')
                let currentFieldA
                let currentFieldB
                if (fields.length === 1) {
                  currentFieldA = a[fields[0]]
                  currentFieldB = b[fields[0]]
                }
                if (fields.length === 2) {
                  currentFieldA = a[fields[0]]?.[fields[1]]
                  currentFieldB = b[fields[0]]?.[fields[1]]
                }
                if (fields.length === 3) {
                  currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                  currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                }
                if (type === 'date') {
                  return new Date(convertDateToISO(currentFieldA)) >
                    new Date(convertDateToISO(currentFieldB)) && order === 'ASC'
                    ? 1
                    : -1
                }
                if (type === 'monetary') {
                  return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                    Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                    order === 'ASC'
                    ? 1
                    : -1
                }
                return currentFieldA > currentFieldB && order === 'ASC' ? 1 : -1
              })
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                const types = [
                  { key: 'locacao', label: 'LOCAÇÃO' },
                  { key: 'L', label: 'LOCAÇÃO' },
                  { key: 'materia-prima', label: 'MATERIA PRIMA' },
                  { key: 'revenda', label: 'REVENDA' },
                  { key: 'semi-acabado', label: 'SEMI ACABADO' },
                  { key: 'consumo', label: 'USO E CONSUMO' },
                  { key: 'CONSUMO', label: 'USO E CONSUMO' },
                  { key: 'venda', label: 'VENDA' },
                  { key: 'V', label: 'VENDA' },
                  { key: 'M', label: 'MANUTENÇÃO' }
                ]
                const typeProduct = types.find(
                  t => t.key === item.product?.type
                )
                if (typeProduct) {
                  item.product.type = typeProduct.label
                }
                const type = types.find(t => t.key === item.type)
                if (type) {
                  item.type = type.label
                }
                return (
                  <tr key={item.id}>
                    {headers.map(header =>
                      header?.field !== 'actions' && !header.custom ? (
                        <td key={`${header?.field}-${item.id}`}>
                          <p
                            style={{
                              textAlign: 'left'
                            }}
                          >
                            {typeof item[header?.field] === 'boolean' &&
                              (item[header?.field] ? 'Sim' : 'Nâo')}
                            {header?.field.split('.').length === 3 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]?.[header?.field.split('.')[2]]}
                            {header?.field.split('.').length === 2 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]}
                            {header?.field.split('.').length === 1 &&
                              item[header?.field]}
                          </p>
                        </td>
                      ) : header.custom ? (
                        <td key={Math.random() * headers.length + 1}>
                          {customHeaders
                            .find(h => h.field === header.field)
                            .element(item)}
                        </td>
                      ) : (
                        <td key={`actions-${item.id}`} className="actions">
                          {(actions && (
                            <LinkContainer>
                              {actions.map(action => (
                                <Link
                                  to={
                                    action.link ||
                                    (action.linkTo && action.linkTo(item)) ||
                                    '#'
                                  }
                                  className="link"
                                  key={action.name}
                                  title={action.title}
                                  onClick={e => {
                                    if (action.onClick) {
                                      e.preventDefault()
                                      action.onClick(item)
                                    }
                                  }}
                                >
                                  {action.element && action.element(item)}
                                  {action.spanIcon && (
                                    <span className={action.spanIcon} />
                                  )}
                                  {action.icon && (
                                    <FontAwesomeIcon icon={action.icon} />
                                  )}
                                  {action.htmlIcon && action.htmlIcon}
                                </Link>
                              ))}
                            </LinkContainer>
                          )) || (
                            <LinkContainer>
                              {!item.parent_id && (
                                <Link
                                  className="link fa-lg me-2"
                                  key={Math.random()}
                                  title="Visualizar"
                                  to={() => handlerOnClickButtonList(item)}
                                  style={{
                                    pointerEvents: disabledAction?.view
                                      ? 'none'
                                      : 'all',
                                    cursor: disabledAction?.view
                                      ? 'not-allowed'
                                      : 'all',
                                    opacity: disabledAction?.view ? 0.5 : 1
                                  }}
                                >
                                  <span className="fa fa-search" />
                                </Link>
                              )}
                              <div>
                                {!onlyView && (
                                  <Link
                                    className="link fa-lg"
                                    key={Math.random()}
                                    title="Editar"
                                    style={{
                                      pointerEvents: disabledAction?.edit
                                        ? 'none'
                                        : 'all',
                                      cursor: disabledAction?.edit
                                        ? 'not-allowed'
                                        : 'all',
                                      opacity: disabledAction?.edit ? 0.5 : 1
                                    }}
                                    onClick={e => {
                                      if (onActions?.onClickButtonEdit) {
                                        e.preventDefault()
                                        onActions.onClickButtonEdit(item)
                                      }
                                    }}
                                    to={() => handlerOnClickButtonEdit(item)}
                                  >
                                    <span className="fa fa-edit" />
                                  </Link>
                                )}
                                {!notHasChildren && (
                                  <div
                                    key={Math.random()}
                                    title="Remover"
                                    className="link "
                                    onClick={() => {
                                      handlerOnClickButtonRemove(item)
                                    }}
                                  >
                                    <span className="fa fa-remove" />
                                  </div>
                                )}
                              </div>
                            </LinkContainer>
                          )}
                        </td>
                      )
                    )}
                  </tr>
                )
              })) || (
              <tr>
                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="d-flex flex-stack align-items-end h-60px">
        <div className="">
          <div className="dataTables_info" id="kt_datatable">
            Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
            {totalItems} registros
          </div>
        </div>
        <div className="">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={changedParams?.page}
              onPageChange={handleChangePage}
              maxPages={maxPages}
              minPages={minPages}
              setMaxPages={setMaxPages}
              setMinPages={setMinPages}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DataTable
