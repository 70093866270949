import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import {
  CELL_PHONE,
  CPF,
  EMAIL,
  INPUT_MASK,
  OPTIONS_TYPE_PARTNER,
  OPTIONS_TYPE_PIX_KEY,
  OPTIONS_YES_OR_NO,
  RANDOM_KEY,
  TYPE_PERSON
} from 'common/constants'
import { FormContainer } from './styles'
import { Title } from 'components/Title'
import { cnpjMask, cpfMask } from 'utlis/mask'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type PartnerData = {
  user?: User
  own_team?: number
  type?: string
  create_user?: number
  different_ownership?: number
  cpf_cnpj_ownership?: string
  type_person?: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: PartnerData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormPartner = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<PartnerData>()
  const [differentOwnership, setDifferentOwnership] = useState(false)
  const [typePerson, setTypePerson] = useState('')
  const [typePixKey, setTypePixKey] = useState('')
  const [mask, setMask] = useState('*********************')

  const optionsStates = [
    {
      name: 'AC',
      value: 'AC'
    },
    {
      name: 'AL',
      value: 'AL'
    },
    {
      name: 'AM',
      value: 'AM'
    },
    {
      name: 'AP',
      value: 'AP'
    },
    {
      name: 'BA',
      value: 'BA'
    },
    {
      name: 'CE',
      value: 'CE'
    },
    {
      name: 'DF',
      value: 'DF'
    },
    {
      name: 'ES',
      value: 'ES'
    },
    {
      name: 'GO',
      value: 'GO'
    },
    {
      name: 'MA',
      value: 'MA'
    },
    {
      name: 'MG',
      value: 'MG'
    },
    {
      name: 'MS',
      value: 'MS'
    },
    {
      name: 'MT',
      value: 'MT'
    },
    {
      name: 'PA',
      value: 'PA'
    },
    {
      name: 'PB',
      value: 'PB'
    },
    {
      name: 'PE',
      value: 'PE'
    },
    {
      name: 'PI',
      value: 'PI'
    },
    {
      name: 'PR',
      value: 'PR'
    },
    {
      name: 'RJ',
      value: 'RJ'
    },
    {
      name: 'RN',
      value: 'RN'
    },
    {
      name: 'RO',
      value: 'RO'
    },
    {
      name: 'RR',
      value: 'RR'
    },
    {
      name: 'RS',
      value: 'RS'
    },
    {
      name: 'SC',
      value: 'SC'
    },
    {
      name: 'SE',
      value: 'SE'
    },
    {
      name: 'SP',
      value: 'SP'
    },
    {
      name: 'TO',
      value: 'TO'
    }
  ]
  const handleLabelDocument = {
    [TYPE_PERSON.PF.ABBREVIATION]: TYPE_PERSON.PF.DOCUMENT.toUpperCase(),
    [TYPE_PERSON.PJ.ABBREVIATION]: TYPE_PERSON.PJ.DOCUMENT.toUpperCase(),
    '': 'Número do Documento'
  }
  const handleMaskDocument = {
    [TYPE_PERSON.PF.ABBREVIATION]: {
      mask: TYPE_PERSON.PF.MASK,
      type: TYPE_PERSON.PF.DOCUMENT
    },
    [TYPE_PERSON.PJ.ABBREVIATION]: {
      mask: TYPE_PERSON.PJ.MASK,
      type: TYPE_PERSON.PJ.DOCUMENT
    },
    '': {}
  }
  const handleMaskTypePixKey: any = {
    [CPF]: {
      mask: TYPE_PERSON.PF.MASK,
      type: TYPE_PERSON.PF.DOCUMENT
    },
    [CELL_PHONE]: {
      mask: INPUT_MASK.CELL_PHONE
    },
    [RANDOM_KEY]: {}
  }
  const handleCnpjCpfMask = (value: string) => {
    if (!value) return value
    if (value?.length === 14) {
      setMask(TYPE_PERSON.PJ.MASK)
      return cnpjMask(value)
    }
    setMask(TYPE_PERSON.PF.MASK)
    return cpfMask(value)
  }
  useEffect(() => {
    if (initialValues) {
      const cpf_cnpj_ownership = handleCnpjCpfMask(
        initialValues?.cpf_cnpj_ownership
      )
      setDefaultValues({
        ...initialValues,
        create_user: initialValues?.user ? 1 : 0,
        cpf_cnpj_ownership
      })

      const verifyIfTypeOfPersonIsEqualToPF =
        initialValues?.type_person?.toLowerCase() ===
        TYPE_PERSON.PF.ABBREVIATION.toLowerCase()
      setTypePerson(
        verifyIfTypeOfPersonIsEqualToPF
          ? TYPE_PERSON.PF.ABBREVIATION
          : TYPE_PERSON.PJ.ABBREVIATION
      )
      setDifferentOwnership(!!initialValues?.different_ownership)
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: PartnerData) => {
    data.own_team = data.type === 'Colaborador' ? 1 : 0
    const id = initialValues?.idUpdate
    const formData = {
      ...data
    }
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          try {
            await api.post(apiCreate(), formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data,
            user: {
              ...defaultValues?.user,
              ...data.user
            }
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data,
            user: {
              ...defaultValues?.user,
              ...data.user
            }
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error: any) {
            if (
              error?.response?.data?.message
                ?.toLowerCase()
                .includes('partner already registered.')
            ) {
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Documento informado já em uso, por favor, tente novamente.'
              })
              disableLoading()
              return
            }
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <FormContainer>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className="form">
          <div className="row mb-default">
            <Title title="Dados Pessoais" className="mt-0" />
            <Input
              className="col-6"
              name="name"
              label="Nome/Razão Social"
              rules={{ required: true }}
            />
            <Select
              className="col-2"
              name="type_person"
              label="Tipo Pessoa"
              options={[
                {
                  name: TYPE_PERSON.PF.NAME,
                  value: TYPE_PERSON.PF.ABBREVIATION
                },
                {
                  name: TYPE_PERSON.PJ.NAME,
                  value: TYPE_PERSON.PJ.ABBREVIATION
                }
              ]}
              onChange={event => {
                setTypePerson(event.target.value)
              }}
              controlled
              blank
              defaultValue=""
              rules={{ required: true }}
            />
            <Input
              className="col-2"
              name="document"
              label={handleLabelDocument[typePerson]}
              mask={handleMaskDocument[typePerson]}
              controlled
              disabled={!!(typePerson === '')}
              rules={{ required: true }}
            />
            <Select
              className="col-2"
              name="type"
              label="Tipo"
              options={OPTIONS_TYPE_PARTNER}
              // onChange={event => {
              //   setTypePerson(event.target.value)
              // }}
              // controlled
              blank
              defaultValue=""
              rules={{ required: true }}
            />
          </div>

          <div className="row mb-default">
            <Title title="Endereço" />
            <Input
              className="col-2"
              label="CEP"
              name="zip_code"
              mask={{
                mask: '99999-999',
                type: 'zipCode',
                prefixInputsData: ''
              }}
              controlled
              rules={{
                required: true,
                minLength: { value: 8, message: 'Cep Inválido' }
              }}
            />
            <Input
              className="col-4"
              label="Logradouro"
              name="street"
              rules={{ required: true }}
            />
            <Input
              className="col-2"
              label="Número"
              name="number"
              rules={{ required: true }}
            />
            <Input className="col-4" label="Complemento" name="complement" />
          </div>

          <div className="row mb-default">
            <Input
              className="col-4"
              label="Bairro"
              name="district"
              rules={{ required: true }}
            />
            <Input
              className="col-4"
              label="Cidade"
              name="city"
              rules={{ required: true }}
            />

            <Select
              className="col-2"
              label="Estado"
              name="state"
              rules={{ required: true }}
              options={optionsStates}
              defaultValue=""
              blank
            />
          </div>
          <div className="row mb-default">
            <Title title="Contatos" />
            <Input
              className="col-4"
              label="E-mail"
              name="email"
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
            />
            <Input
              className="col-4"
              label="E-mail Secundário"
              name="secondary_email"
              rules={{
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
            />
            <Input
              className="col-2"
              label="Celular"
              name="cell_phone"
              mask={{
                mask: INPUT_MASK.CELL_PHONE
              }}
              controlled
              rules={{
                required: true,
                minLength: { message: 'Número Invalido', value: 15 }
              }}
            />
            <Input
              className="col-2"
              label="Telefone"
              name="phone"
              mask={{
                mask: INPUT_MASK.PHONE
              }}
              controlled
              rules={{
                minLength: { message: 'Número Invalido', value: 14 }
              }}
            />
          </div>
          <div className="row mb-default">
            <Title title="Dados Bancários" />

            <Select
              className="col-2"
              label="Titularidade diferente"
              name="different_ownership"
              rules={{ required: true }}
              options={OPTIONS_YES_OR_NO}
              onChange={event => {
                setDifferentOwnership(!!Number(event.target.value))
              }}
              controlled
              defaultValue=""
              blank
            />
            {differentOwnership && (
              <>
                <Input
                  className="col-4"
                  label="Nome do Titular"
                  name="name_ownership"
                  rules={{ required: true }}
                />
                <Input
                  className="col-2"
                  label="CPF/CNPJ do Titular"
                  name="cpf_cnpj_ownership"
                  rules={{ required: true }}
                  mask={{
                    mask,
                    lazy: true
                  }}
                  controlled
                  // value={ownerDocument}
                  // onChange={event => onSearchItem(event.target.value)}
                />
              </>
            )}
            {/* <div className={'row ' + !!differentOwnership ? 'show' : 'hidden'}>
              <Input
                className="col-4"
                label="Nome do Titular"
                name="name_ownership"
                rules={{ required: true }}
              />
              <Input
                className="col-2"
                label="Cpf/Cnpj do Titular"
                name="cpf_cnpj_ownership"
                rules={{ required: true }}
              />
            </div> */}

            <Select
              className="col-2"
              label="Tipo"
              name="type_account"
              rules={{ required: true }}
              options={[
                {
                  name: 'Conta Corrente',
                  value: 'Conta Corrente'
                },
                {
                  name: 'Conta Poupança',
                  value: 'Conta Poupança'
                }
              ]}
              defaultValue=""
              blank
            />
            <Input
              className="col-2"
              label="Cód. Banco"
              name="bank_code"
              rules={{ required: true }}
            />
            <Input
              className="col-2"
              label="Agência"
              name="agency"
              rules={{ required: true }}
            />
            <Input
              className="col-2"
              label="Conta"
              name="account"
              rules={{ required: true }}
            />
            <Input
              className="col-2"
              label="Conta DV"
              name="account_digit"
              rules={{ required: true }}
            />
          </div>
          <div className="row mb-2 ">
            <Title
              hasSeparator={false}
              CustomTitle={() => {
                return <h5>Pix</h5>
              }}
            />
            <Select
              className="col-2"
              label="Tipo de Chave"
              name="type_pix_key"
              options={OPTIONS_TYPE_PIX_KEY}
              onChange={event => {
                setTypePixKey(event.target.value)
              }}
              value={typePixKey}
              defaultValue=""
              blank
            />
            <Input
              className="col-4"
              label="Chave"
              name="pix_key"
              mask={handleMaskTypePixKey[typePixKey]}
              rules={
                typePixKey === EMAIL
                  ? {
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Email inválido'
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className="row mb-default">
            <Title title="Sistema" />
            {typeForm === 'update' && (
              <Select
                className="col-2"
                name="active"
                label="Ativo"
                rules={{ required: true }}
                options={[
                  {
                    name: 'Sim',
                    value: 1
                  },
                  {
                    name: 'Não',
                    value: 0
                  }
                ]}
              />
            )}
            {defaultValues?.create_user ? (
              <>
                <Input
                  className="col-2"
                  label="Nova Senha"
                  name="user.password"
                  type="password"
                />
              </>
            ) : (
              <Select
                className="col-2"
                label="Criar Usuário"
                name="create_user"
                rules={{ required: true }}
                options={OPTIONS_YES_OR_NO}
                defaultValue=""
                blank
              />
            )}

            <Select
              className="col-2"
              label="Emite NF"
              name="issue_invoice"
              rules={{ required: true }}
              options={OPTIONS_YES_OR_NO}
              defaultValue=""
              blank
            />
            {/* <Select
              className="col-2"
              label="Equipe Própria"
              name="own_team"
              rules={{ required: true }}
              options={OPTIONS_YES_OR_NO}
              defaultValue=""
              blank
            /> */}
          </div>
          <div className="row mb-default">
            <Title title="Observações" />
            <Textarea
              className="col-12"
              label=""
              name="observations"
              style={{ minHeight: 150 }}
            />
          </div>
          <div className="card-footer d-flex justify-content-end px-0 mt-5">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </FormContainer>
  )
}
