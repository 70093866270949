import React from 'react'
import DataTable from '../../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'

const UserList = (): JSX.Element => (
  <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={toolsList}>
    <DataTable
      source={nameSource}
      entity={nameEntity}
      format={{ orderBy: 'name' }}
      notHasChildren
      headers={headers}
      customHeaders={[
        {
          name: 'Ativo',
          field: 'active',
          sortable: false,
          element: item => <p>{item.active ? 'Sim' : 'Não'}</p>
        }
      ]}
    />
  </Container>
)

export default UserList
