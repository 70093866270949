import React from 'react'
import PrivateRoutes from '../Route'
import CreatePerImport from '../../pages/Commercial/Hirings/Import'
import List from '../../pages/Commercial/Hirings/List'
import Create from '../../pages/Commercial/Hirings/Create'
import View from '../../pages/Commercial/Hirings/View'
import Update from '../../pages/Commercial/Hirings/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const HiringsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/hirings" exact component={List} />
    <PrivateRoutes
      path="/commercial/hirings/import"
      exact
      component={CreatePerImport}
    />
    <PrivateRoutes path="/commercial/hirings/create" exact component={Create} />
    <PrivateRoutes path="/commercial/hirings/view/:id" exact component={View} />
    <PrivateRoutes
      path="/commercial/hirings/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
