export const WARNING_TYPE_ENUM: Record<string, string> = {
  show: 'Apresentar',
  download: 'Download'
}

export const WARNING_TYPE_OPTIONS = Object.entries(WARNING_TYPE_ENUM).map(
  ([key, name]) => ({
    name,
    value: key
  })
)
