import React from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import { WARNING_TYPE_ENUM } from 'common/constants/warning'
import { getFileName } from 'utlis/getFileName'
import moment from 'moment'
import { DATE_FORMATS } from 'common/constants'

const WarningList = (): JSX.Element => (
  <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={toolsList}>
    <DataTable
      source={nameSource}
      entity={nameEntity}
      format={{ orderBy: 'title' }}
      notHasChildren
      headers={headers}
      customHeaders={[
        {
          name: 'Tipo',
          field: 'type',
          sortable: true,
          element: item => {
            return <p>{WARNING_TYPE_ENUM[item.type]}</p>
          }
        },
        {
          name: 'Valido até',
          field: 'valid_at',
          sortable: true,
          element: item => {
            return (
              <p>
                {moment(item.valid_at, DATE_FORMATS.LOCALE_WITH_TIME).format(
                  DATE_FORMATS.LOCALE
                )}
              </p>
            )
          }
        },
        {
          name: 'Arquivo',
          field: 'file',
          sortable: true,
          element: item => {
            return (
              <a
                href={item.file_url}
                target="_blank"
                className="d-flex"
                rel="noreferrer"
                style={{ alignItems: 'center' }}
              >
                <span className="fa fa-external-link me-2"></span>
                <p>{getFileName({ fileName: item.file })}</p>
              </a>
            )
          }
        }
      ]}
    />
  </Container>
)

export default WarningList
