import React from 'react'
import List from '../../pages/Config/Warnings/List'
import Update from '../../pages/Config/Warnings/Update'
import Create from '../../pages/Config/Warnings/Create'
import View from '../../pages/Config/Warnings/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const WarningsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warnings" exact component={List} />
    <Route path="/warnings/update/:id" component={Update} exact />
    <Route path="/warnings/create" component={Create} exact />
    <Route path="/warnings/view/:id" component={View} exact />
  </CustomSwitch>
)
