/* eslint-disable react/display-name */
import React, { memo } from 'react'
import DataTable from 'components/DataTable'
import { nameEntity, nameSource } from '../../domain/info'
import { headers } from '../../domain/headers'
import { useAuth } from 'hooks/auth'

export const HiringDataTable = memo(({ searchParams }: any) => {
  const { user } = useAuth()
  return (
    <DataTable
      entity={nameEntity}
      source={nameSource}
      format={{ orderBy: 'id' }}
      notHasChildren
      headers={headers}
      customHeaders={[
        {
          name: 'Parceiro',
          field: 'partner_id',
          sortable: true,
          element: item => {
            return <p>{item?.partner?.name}</p>
          }
        },
        {
          name: 'Banco',
          field: 'bank_id',
          sortable: true,
          element: item => {
            return <p>{item?.bank?.name}</p>
          }
        },
        {
          name: 'Status',
          field: 'status',
          sortable: true,
          element: item => {
            return <p>{item.status}</p>
          }
        }
      ]}
      searchParameters={[
        {
          ...searchParams[0],
          partner_id:
            user.role_id === 1
              ? searchParams[0]?.partner_id || undefined
              : user.uid
        }
      ]}
    />
  )
})
