import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
import { StringParam, useQueryParam } from 'use-query-params'
import { Loading } from 'components/Loading'
import { useHistory } from 'react-router-dom'
import { FilterContainer } from './styles'
import { Select } from 'components/Form'
import {
  OPTIONS_TYPE_PARTNER_MULTISELECT,
  OPTIONS_YES_OR_NO
} from 'common/constants'
import { MultiSelect } from 'primereact/multiselect'
import './customMultiselect.css'

const List: React.FC = () => {
  const history = useHistory()
  const [status] = useQueryParam('status', StringParam)
  const [approved] = useQueryParam('approved', StringParam)
  const [active] = useQueryParam('active', StringParam)
  const [type] = useQueryParam('type', StringParam)
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [activeValue, setActiveValue] = useState<string>()
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])

  useEffect(() => {
    setSearchParameters([{ status, approved, active, type }])
    setActiveValue(active)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [status, approved, active, type])

  const handleClearFilter = useCallback(() => {
    setIsLoading(true)
    setSelectedTypes([])
    history.push('/commercial/partners')
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [history])

  const handleSetFilter = useCallback(() => {
    let url = '/commercial/partners?'
    if (activeValue) {
      url += `active=${activeValue}&`
    }
    if (selectedTypes) {
      url += `type=${selectedTypes.join(',')}`
    }
    history.push(url)
  }, [activeValue, history, selectedTypes])

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <div className="row">
          <div className="col-md-3">
            <Select
              name="active"
              label="Ativo"
              id="active"
              options={OPTIONS_YES_OR_NO}
              blank
              value={activeValue}
              onChange={event => setActiveValue(event.target.value)}
            />
          </div>
          <div className="col-md-3 custom-multi-select">
            <label htmlFor="type" className="col-form-label fw-bold fs-6">
              Tipo
            </label>
            <MultiSelect
              value={selectedTypes}
              onChange={e => setSelectedTypes(e.value)}
              options={OPTIONS_TYPE_PARTNER_MULTISELECT}
              optionValue="value"
              optionLabel="name"
              placeholder="Selecione"
              className="form-select form-select-solid fw-bold"
              showSelectAll={false}
              closeIcon={<></>}
              panelHeaderTemplate={<></>}
            />
          </div>
        </div>
        <footer>
          <button className="btn btn-light-primary" onClick={handleClearFilter}>
            LIMPAR
          </button>
          <button className="btn btn-primary" onClick={handleSetFilter}>
            BUSCAR
          </button>
        </footer>
        <hr className="divider" />
      </FilterContainer>
      <DataTable
        entity={nameEntity}
        source={nameSource}
        format={{ orderBy: 'name' }}
        notHasChildren
        headers={headers}
        searchParameters={searchParameters}
        customHeaders={[
          {
            name: 'Ativo',
            field: 'active',
            sortable: true,
            element: item => {
              return <p>{item.active ? 'Sim' : 'Não'}</p>
            }
          }
        ]}
      />
    </Container>
  )
}
export default List
